import { Box, Container, Grid, Paper, Toolbar } from '@mui/material';
import Dashboard from '../../../../components/Student/Dashboard';
import { Styles } from '../../../../styles/global';
import { useEffect, useRef, useState } from 'react';
import { getTutorials } from '../../../../utils/API';
import { useParams, useSearchParams } from 'react-router-dom';
import TutorialsView from '../../../../components/Views';

function Tutorials() {
  const [tutorials, setTutorials] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const topRef = useRef(null); // Create a ref for the top of the page
  const { pid } = useParams();

  useEffect(() => {
    if (!searchParams.get('limit')) {
      setSearchParams(`${searchParams.toString()}&limit=10`);
    }
    if (!searchParams.get('page')) {
      setSearchParams(`${searchParams.toString()}&page=1`);
    }
  }, [searchParams, setSearchParams]);

  useEffect(() => {
    async function fetchTutorials() {
      try {
        const tutorialsResponse = await getTutorials(searchParams.toString());
        setTutorials(tutorialsResponse?.data || []);
      } catch (error) {
        console.log(error);
      }
    }
    try {
      fetchTutorials();
    } catch (error) {
      console.log(error);
    }
  }, [searchParams]);

  return (
    <>
      <Box {...Styles.mainBox}>
        <Toolbar />

        {/* Container With Max Width = lg breakpoint */}
        <Container {...Styles.customContainer('xl')}>
          {/*Outer Grid Container */}
          <Grid container spacing={3}>
            {/*Inner Grid Begins*/}

            <Grid item lg={1} sm={12} xs={12} ref={topRef}>
              <></>
            </Grid>

            {/*Inner Grid Item 1: Page Title */}
            <Grid item lg={11} sm={12} xs={12} ref={topRef}>
              <Paper {...Styles.dashboardPageWidget()}>Tutorials</Paper>
            </Grid>

            <Grid item lg={1} sm={12} xs={12} ref={topRef}>
              <></>
            </Grid>

            {/*Inner Grid Item 3: Projects View */}
            <Grid item lg={11} sm={12} xs={12}>
              <Paper {...Styles.dashboardContentMinWidget()}>
                <TutorialsView
                  items={tutorials}
                  topRef={topRef}
                  link={`/student/dashboard/project/${pid}/tutorials`}
                  itemType={'tutorials'}
                />
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
}

export default function StudentDashboardTutorials() {
  return <Dashboard Page={Tutorials} />;
}

import { Box, Input } from '@mui/material';
import { useState } from 'react';

export default function Youtube({ component, handleChange }) {
  const [localContent, setLocalContent] = useState(component?.videoID || '');
  function handleContentChange() {
    const newComponent = { ...component, videoID: localContent };
    handleChange(newComponent);
  }
  return (
    <Box display="flex" flexDirection={'column'} gap={1}>
      <h5>Youtube Link</h5>
      <Input
        value={localContent}
        placeholder='Enter the video ID: Ex: "https://www.youtube.com/embed/VIDEO_ID. Only the "VIDEO_ID" is needed'
        fullWidth
        onChange={(e) => setLocalContent(e.target.value)}
        onBlur={() => handleContentChange()}
      />
    </Box>
  );
}

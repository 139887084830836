import { Box, Typography } from '@mui/material';

export default function TextBanner({ text, bgcolor, textColor, body }) {
  return (
    <Box
      sx={{
        height: '200px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: textColor,
        fontSize: '2.6rem',
        bgcolor,
        margin: 2,
        borderRadius: '10px',
        boxShadow: 2,
        fontFamily: 'Lato, Arial, sans-serif',
        flexDirection: 'column',
        padding: 1,
      }}
    >
      <p>{text}</p>
      {body && (
        <Typography variant="body1" sx={{ marginY: 2 }}>
          {body}
        </Typography>
      )}
    </Box>
  );
}

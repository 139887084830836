import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import { useCallback, useState } from 'react';
import { AtendeeUIList } from '../../../utils/Atendee/contants';
import FormComponent from './Fields';

function AddComponent({ component, setComponent, handleClick }) {
  return (
    <Box display="flex" alignItems="center" sx={{ margin: 3, marginBottom: 2 }}>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">UI</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={component}
          label="Age"
          onChange={(e) => setComponent(e.target.value)}
        >
          {AtendeeUIList.map((ui) => (
            <MenuItem key={ui.value} value={ui.value}>
              {ui.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Button
        variant="outlined"
        size="small"
        onClick={handleClick}
        disabled={!component}
      >
        Add Component
      </Button>
    </Box>
  );
}

export default function PagesForm({ content, setContent }) {
  const [component, setComponent] = useState('');
  const handleClick = () => {
    setContent([...content, { component }]);
    setComponent('');
  };
  const handleChange = useCallback(
    (index, action, newComponent = null) => {
      if (action === 'delete') {
        setContent((prevPage) => {
          const newContent = prevPage.filter((_, i) => i !== index);
          return [...newContent];
        });
      } else {
        setContent((prevPage) => {
          const newContent = [...prevPage];
          newContent[index] = newComponent;
          return [...newContent];
        });
      }
    },
    [setContent]
  );

  return (
    <Box>
      {/* Content */}
      <Box>
        {content.map((component, index) => (
          <Box key={index}>
            <FormComponent
              index={index}
              uiComponent={component}
              handleChange={handleChange}
            />
          </Box>
        ))}
      </Box>

      {/* Add Component UI */}
      <AddComponent
        component={component}
        setComponent={setComponent}
        handleClick={handleClick}
      />
    </Box>
  );
}

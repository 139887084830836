import { Box, Typography } from '@mui/material';

export default function Footer() {
  return (
    <Box
      sx={{
        backgroundColor: '#00274c',
        color: '#ffdb53',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '50px',
        padding: '20px',
      }}
    >
      <Typography variant="body1">
        © 2024 University of Michigan-Dearborn
      </Typography>
    </Box>
  );
}

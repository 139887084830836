import * as React from 'react';
import Box from '@mui/material/Box';
import MuModal from '@mui/material/Modal';
import Button from '@mui/material/Button';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: { lg: 800, md: 600, sm: 'auto' },
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  overflow: 'auto',
  borderRadius: '7px',
  maxHeight: '80%',
};

export default function Modal({ open, handleClose, cta = 'Close', children }) {
  //   const [open, setOpen] = React.useState(false);
  //   const handleOpen = () => setOpen(true);
  //   const handleClose = () => setOpen(false);

  return (
    <div>
      <MuModal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ overflow: 'auto' }}
      >
        <Box sx={style}>
          <Button
            variant="contained"
            color="success"
            sx={{ position: 'absolute', right: '9%' }}
            onClick={handleClose}
          >
            {cta}
          </Button>
          {children}
        </Box>
      </MuModal>
    </div>
  );
}

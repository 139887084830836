import { Box, Button } from '@mui/material';
import Text from './Text';
import Video from './Video';
import Alert from './Alert';
import Link from './Link';
import TextAccordian from './TextAccordian';
import Image from './Image';
import ImageAccordian from './ImageAccordian';
import ListA from './ListA';
import ListB from './ListA';

function FormBox({ index, handleChange, uiComponent }) {
  const handleInnerChange = (newComponent) => {
    handleChange(index, 'update', newComponent);
  };
  const BoxWrapper = ({ children }) => (
    <Box width="100%" marginTop={1}>
      <Box
        display="flex"
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <h5>{uiComponent?.component || ''}</h5>
        <Button
          variant="text"
          color="error"
          onClick={() => handleChange(index, 'delete', null)}
        >
          Delete
        </Button>
      </Box>
      <Box padding={1} border={1}>
        <Box xs={12}>{children}</Box>
      </Box>
    </Box>
  );
  const DynamicComponent = (component) => {
    switch (component) {
      case 'Text':
        return (
          <BoxWrapper>
            <Text component={uiComponent} handleChange={handleInnerChange} />
          </BoxWrapper>
        );
      case 'Video':
        return (
          <BoxWrapper>
            <Video component={uiComponent} handleChange={handleInnerChange} />
          </BoxWrapper>
        );
      case 'Alert':
        return (
          <BoxWrapper>
            <Alert component={uiComponent} handleChange={handleInnerChange} />
          </BoxWrapper>
        );
      case 'Divider':
        return (
          <BoxWrapper>
            <>No input fields for dividers</>
          </BoxWrapper>
        );
      case 'Link':
        return (
          <BoxWrapper>
            <Link component={uiComponent} handleChange={handleInnerChange} />
          </BoxWrapper>
        );
      case 'TextAccordian':
        return (
          <BoxWrapper>
            <TextAccordian
              component={uiComponent}
              handleChange={handleInnerChange}
            />
          </BoxWrapper>
        );
      case 'Image':
        return (
          <BoxWrapper>
            <Image component={uiComponent} handleChange={handleInnerChange} />
          </BoxWrapper>
        );
      case 'ImageAccordian':
        return (
          <BoxWrapper>
            <ImageAccordian
              component={uiComponent}
              handleChange={handleInnerChange}
            />
          </BoxWrapper>
        );
      case 'UnorderedList':
        return (
          <BoxWrapper>
            <ListA component={uiComponent} handleChange={handleInnerChange} />
          </BoxWrapper>
        );
      case 'OrderedList':
        return (
          <BoxWrapper>
            <ListB component={uiComponent} handleChange={handleInnerChange} />
          </BoxWrapper>
        );
      default:
        return <></>;
    }
  };
  return <>{DynamicComponent(uiComponent?.component)}</>;
}
export default function FormComponent({ index, handleChange, uiComponent }) {
  return (
    <Box>
      <FormBox
        index={index}
        handleChange={handleChange}
        uiComponent={uiComponent}
      />
    </Box>
  );
}

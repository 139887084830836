import React from 'react';
import DynamicComponent from './DynamicComponent';
import { Box } from '@mui/material';

function Tutorial({ tutorial }) {
  return (
    <>
      {tutorial &&
        tutorial?.content?.map((component, index) => {
          return (
            <Box marginY={1} key={index}>
              <DynamicComponent component={component} />
            </Box>
          );
        })}
    </>
  );
}
export default Tutorial;

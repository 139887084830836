import { useEffect } from 'react';
import {
  Text,
  Image,
  Video,
  TextAccordian,
  Alert,
  Divider,
  ImageAccordian,
  Link,
  ListA,
  ListB,
} from '../../UI';
export default function DynamicComponent({ component }) {
  switch (component?.component) {
    case 'Text':
      return <Text content={component?.content} variant={component?.variant} />;
    case 'Image':
      return (
        <Image
          src={`${process.env.REACT_APP_SPACES_ENDPOINT_STATIC}${component?.src}`}
          alt={component?.alt}
        />
      );
    case 'Video':
      return <Video videoLink={component?.videoLink} />;
    case 'TextAccordian':
      return (
        <TextAccordian title={component?.title} details={component?.detail} />
      );
    case 'ImageAccordian':
      return (
        <ImageAccordian
          title={component?.title}
          imgAlt={component?.imgAlt}
          imgSrc={`${process.env.REACT_APP_SPACES_ENDPOINT_STATIC}${component?.imgSrc}`}
        />
      );
    case 'UnorderedList':
      return <ListA items={component?.items} />;
    case 'OrderedList':
      return <ListB items={component?.items} />;
    case 'Link':
      return <Link to={component?.to} text={component?.text} />;
    case 'Alert':
      return (
        <Alert severity={component?.severity} message={component?.message} />
      );
    case 'Divider':
      return <Divider />;
    default:
      return <></>;
  }
}

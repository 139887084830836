export const TutorialUIList = [
  { value: 'Text', label: 'Text' },
  { value: 'Image', label: 'Image' },
  { value: 'Video', label: 'Youtube Video' },
  { value: 'TextAccordian', label: 'Accordian - Text' },
  { value: 'ImageAccordian', label: 'Accordian - Image' },
  { value: 'UnorderedList', label: 'Unordered List' },
  { value: 'OrderedList', label: 'Ordered List' },
  { value: 'Link', label: 'Link' },
  { value: 'Alert', label: 'Alert' },
  { value: 'Divider', label: 'Divider' },
];

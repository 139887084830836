import { Grid, Button, Container } from '@mui/material';
import { Link } from 'react-router-dom';

export default function TwoButtonLink({
  button1Link,
  button1Text,
  button2Link,
  button2Text,
}) {
  return (
    <Grid container spacing={2} marginY={2}>
      <Container maxWidth="md">
        <Grid container item xs={12} spacing={2}>
          <Grid item xs={12} md={6}>
            <Button
              component={Link}
              to={button1Link}
              variant="contained"
              color="primary"
              fullWidth
            >
              {button1Text}
            </Button>
          </Grid>
          <Grid item xs={12} md={6}>
            <Button
              component={Link}
              to={button2Link}
              variant="contained"
              color="primary"
              fullWidth
            >
              {button2Text}
            </Button>
          </Grid>
        </Grid>
      </Container>
    </Grid>
  );
}

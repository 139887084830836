import {
  RUBRIC_ADMIN_SECTION as sections,
  RUBRIC_ADMIN_FIELDS as fields,
} from './constants';

const AdminRubricSchema = () => {
  return {
    [sections.type]: {
      title: {
        value: sections.type,
      },
      fields: {
        name: fields.type.name,
        type: 'select',
        label: 'Type',
        placeholder: 'Select type',
        required: false,
        getOptions: () => fields.type.options,
      },
    },
    [sections.department]: {
      title: {
        value: sections.department,
      },
      fields: {
        name: fields.department.name,
        type: 'select',
        label: 'Department',
        placeholder: 'Select department',
        required: false,
        getOptions: () => fields.department.options,
      },
    },
    [sections.course]: {
      title: {
        value: sections.course,
      },
      fields: {
        name: fields.course.name,
        type: 'radio',
        label: 'Course',
        placeholder: 'Select course',
        required: false,
        getOptions: () => fields.course.options,
      },
    },
    [sections.limit]: {
      title: {
        value: sections.limit,
      },
      fields: {
        name: fields.limit.name,
        type: 'select',
        label: 'Year',
        placeholder: 'Select limit',
        required: false,
        getOptions: () => fields.limit.options,
      },
    },
  };
};

export default AdminRubricSchema;

import {
  Box,
  Button,
  Chip,
  Divider,
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import { StylesForm } from '../../../styles/global';
import { useEffect, useState } from 'react';
import {
  createJudgeAssignments,
  getRubrics,
  getUsers,
} from '../../../utils/API';
import ViewRubric from '../../Rubrics/ViewRubric';
import { Check } from '@mui/icons-material';

// Widget for Adding Judge Assignments
const AddAssignment = ({ project, flag, setFlag }) => {
  const [rubric, setRubric] = useState('');
  const [availableRubrics, setAvailableRubrics] = useState([]);
  const [judge, setJudge] = useState('');
  const [availableJudges, setAvailableJudges] = useState([]);

  const handleCreate = async () => {
    try {
      await createJudgeAssignments({
        rubric,
        judge,
        project: project.id,
      });

      alert('Assignment Created Successfully');
      setRubric('');
      setJudge('');
      setFlag(!flag);
    } catch (error) {
      console.error('Error Creating Assignment:', error);
      alert('Error Creating Assignment');
    }
  };

  useEffect(() => {
    if (!project) return;

    // Fetch Rubrics Based On Project Data
    async function fetchRubrics() {
      let temp = [];
      let response = await getRubrics(
        `course=${project?.course}&department=${project?.department}&type=Department`
      );
      temp = [...response?.data?.data];
      response = await getRubrics(`type=BestInCollege`);
      temp = [...temp, ...response?.data?.data];
      response = await getRubrics(`type=Innovation`);
      temp = [...temp, ...response?.data?.data];
      response = await getRubrics(`type=Sponsor`);
      temp = [...temp, ...response?.data?.data];
      response = await getRubrics(`type=Other`);
      temp = [...temp, ...response?.data?.data];
      setAvailableRubrics(temp);
    }

    // Fetch Judges Based On Project Data
    async function fetchJudges() {
      const response = await getUsers(
        `role=JUDGE&department=${project?.department}&course=${project?.course}`
      );
      setAvailableJudges(response?.data?.data);
    }

    fetchRubrics();
    fetchJudges();
  }, [project, flag]);

  return (
    <>
      {project && (
        <>
          {/* Project Title + Due Date */}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            {/* Add Assignments */}
            <Typography {...StylesForm.text('h6')}>
              Add Judge Assignments
            </Typography>
            <Button disabled={!rubric || !judge} onClick={handleCreate}>
              Create
            </Button>
          </Box>

          <Divider />

          {/* Assignment Form */}
          <Typography {...StylesForm.text('p')}>Rubric</Typography>

          <FormControl sx={{ m: 1, minWidth: 120 }}>
            <Select
              value={rubric}
              onChange={(e) => setRubric(e.target.value)}
              displayEmpty
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {availableRubrics?.map((rubric) => (
                <MenuItem key={rubric._id} value={rubric._id}>
                  <Box display="flex" gap={2} alignItems={'center'}>
                    {rubric?.title}
                    <Chip label={rubric?.type} />
                    <Chip label={rubric?.course} />
                    <Chip label={rubric?.department} />
                  </Box>
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>
              Available Rubrics Based On Project Data
            </FormHelperText>
          </FormControl>

          <Typography {...StylesForm.text('p')}>Judge</Typography>

          <FormControl sx={{ m: 1, minWidth: 120 }}>
            <Select
              value={judge}
              onChange={(e) => setJudge(e.target.value)}
              displayEmpty
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {availableJudges?.map((judge) => (
                <MenuItem key={judge._id} value={judge._id}>
                  <Box display="flex" gap={2} alignItems={'center'}>
                    {judge?.fullName}
                    <Chip label={judge?.course} />
                    <Chip label={judge?.department} />
                    <Chip label={judge?.year} />
                    {judge?.interest && <Chip label={judge?.interest} />}
                    {project?.projectBids?.includes(judge?._id) && (
                      <Chip
                        icon={<Check />}
                        label={'Bid'}
                        color={'success'}
                        variant="outlined"
                      />
                    )}
                  </Box>
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>
              Available Rubrics Based On Project Data
            </FormHelperText>
          </FormControl>

          {rubric && <Chip label="Preview Rubric" />}

          <Divider sx={{ margin: 2 }} />

          {/* View Rubric */}

          {rubric && (
            <ViewRubric
              rubric={availableRubrics.filter((item) => item._id === rubric)[0]}
            />
          )}
        </>
      )}
    </>
  );
};

export default AddAssignment;

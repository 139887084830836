import { Divider, Typography } from '@mui/material';
import { StylesForm } from '../../../styles/global';

// Widget for Project Details
const InformProjectDetails = ({ project }) => {
  return (
    <>
      {project && (
        <>
          {/* Title: Project Details */}
          <Typography {...StylesForm.text('h6')}>Project Details</Typography>

          <Divider />

          {/* Start of Info: Title, Course, Department, Year, Clients, Instructor */}
          <>
            <Typography {...StylesForm.text('p')}>
              Project Title : <strong>{`${project.data.title}`}</strong>
            </Typography>

            <Typography {...StylesForm.text('p')}>
              Course Equivalent : <strong>{`${project.data.course}`}</strong>
            </Typography>

            <Typography {...StylesForm.text('p')}>
              Department : <strong>{`${project.data.department}`}</strong>
            </Typography>

            <Typography {...StylesForm.text('p')}>
              Competition Participant Year :{' '}
              <strong>{`${project.data.year}`}</strong>
            </Typography>

            <Typography {...StylesForm.text('p')}>
              Client(s) : <strong>{`${project.data.clients}`}</strong>
            </Typography>

            <Typography {...StylesForm.text('p')}>
              Instructor(s) : <strong>{`${project.data.instructor}`}</strong>
            </Typography>
          </>
          {/* End of Info */}

          <Divider />
        </>
      )}
    </>
  );
};

export default InformProjectDetails;

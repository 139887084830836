import { Container, Grid } from '@mui/material';

export default function Youtube({ videoId }) {
  return (
    <Grid container spacing={2} marginY={2}>
      <Container maxWidth="md">
        <Grid container item xs={12} spacing={2}>
          <Grid item xs={12}>
            <div
              style={{
                position: 'relative',
                paddingBottom: '56.25%',
                height: 0,
              }}
            >
              <iframe
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                }}
                src={`https://www.youtube.com/embed/${videoId}`}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          </Grid>
        </Grid>
      </Container>
    </Grid>
  );
}

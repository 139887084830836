import * as React from 'react';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Dashboard from '../../../../components/Admin/Dashboard.js';
import { useState, useEffect } from 'react';
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { Styles } from '../../../../styles/global.js';
import {
  deleteProject,
  getAssignments,
  getMainConfig,
  getProject,
} from '../../../../utils/API.js';
import {
  InformProjectAbstract,
  InformPosterImage,
  InformPosterLink,
  InformStation,
  InformProjectDetails,
  AddAssignment,
  InformInbox,
  ManageMembers,
} from '../../../../components/Student/Widgets/index.js';

import { formatDate } from '../../../../utils/dates.js';
import { Alert, Breadcrumbs, Button, Typography } from '@mui/material';
import DeleteableRubric from '../../../../components/Rubrics/DeletableRubric.js';
import AdvancedModal from '../../../../components/AdvancedModal.js';

function DeleteProject({ project }) {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const handleDeletion = async () => {
    try {
      await deleteProject(project?._id);
      alert('Project deleted successfully');
      navigate(`/admin/dashboard/projects?${searchParams.toString()}`);
    } catch (error) {
      console.error('Error deleting Project:', error);
      alert('Error deleting project');
    }
  };
  return (
    <>
      {project && (
        <Box display="flex" justifyContent={'end'}>
          <Button variant="text" color="error" onClick={() => setOpen(true)}>
            Delete Project
          </Button>
          <AdvancedModal
            open={open}
            handleClose={() => setOpen(false)}
            title="Delete Project"
            cta="Delete"
            handleSave={handleDeletion}
          >
            <Alert severity="error">
              Are you sure you want to delete this project? This action is
              irreversible. It will delete the project and all its associated
              data including judge assignments and scores.
            </Alert>
          </AdvancedModal>
        </Box>
      )}
    </>
  );
}

// Page that shows when a project is already created
const ExistingProjectDash = () => {
  const [project, setProject] = useState(null);
  const [config, setConfig] = useState(null);
  const { pid } = useParams();
  const [assignments, setAssignments] = useState([]);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [flag, setFlag] = useState(false);
  const [refresh, setRefesh] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getProject(pid);
        setProject(response.data);
        const configResponse = await getMainConfig();
        setConfig(configResponse?.data?.data);
        const assignmentsResponse = await getAssignments(pid);
        setAssignments(assignmentsResponse?.data?.data);
      } catch (error) {
        navigate('/');
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [pid, navigate, flag, refresh]);

  return (
    <>
      <Box {...Styles.mainBox}>
        <Toolbar />

        {/* Container With Max Width = lg breakpoint */}
        <Container {...Styles.customContainer()}>
          {/*Outer Grid Container */}
          <Grid container spacing={3}>
            {/*Inner Grid Begins*/}

            {/*Inner Grid Item -1: Deletion Feature */}
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <DeleteProject project={project?.data} />
            </Grid>

            {/*Inner Grid Item 0: BreadCrumbs */}
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Box>
                <Breadcrumbs aria-label="breadcrumb">
                  <Link to={`/admin/dashboard?${searchParams.toString()}`}>
                    Dashboard
                  </Link>
                  <Link
                    to={`/admin/dashboard/projects?${searchParams.toString()}`}
                  >
                    Projects
                  </Link>
                  <Typography color="textPrimary">Project</Typography>
                </Breadcrumbs>
              </Box>
            </Grid>

            {/*Inner Grid Item 1: Page Title */}
            <Grid item lg={12} sm={12} xs={12}>
              <Paper {...Styles.dashboardPageWidget()}>
                Project Dashboard View
              </Paper>
            </Grid>

            {/*Inner Grid Item 2: Inform Project Details */}
            <Grid item lg={6} md={12} sm={12} xs={12}>
              <Paper {...Styles.dashboardContentWidget()}>
                <InformProjectDetails project={project} />
              </Paper>
            </Grid>

            {/*Inner Grid Item 3: Project Members */}
            <Grid item lg={6} md={12} sm={12} xs={12}>
              <Paper {...Styles.dashboardContentWidget()}>
                <ManageMembers
                  project={project}
                  flag={flag}
                  setFlag={setFlag}
                  showLink={false}
                />
              </Paper>
            </Grid>

            {/*Inner Grid Item 3b: Project Inbox */}
            <Grid item lg={4} md={12} sm={12} xs={12}>
              <Paper {...Styles.dashboardContentWidget()}>
                <InformInbox
                  project={project?.data}
                  setProject={setProject}
                  refresh={refresh}
                  setRefesh={setRefesh}
                  editMode={true}
                />
              </Paper>
            </Grid>

            {/*Inner Grid Item 4: Project Abstract */}
            <Grid item lg={8} md={12} sm={12} xs={12}>
              <Paper {...Styles.dashboardContentWidget()}>
                <InformProjectAbstract
                  project={project}
                  dueDate={formatDate(config?.dueDates?.abstract)}
                  showLink={false}
                />
              </Paper>
            </Grid>

            {/*Inner Grid Item 5: Poster Link */}
            <Grid item lg={6} md={12} sm={12} xs={12}>
              <Paper {...Styles.dashboardContentWidget()}>
                <InformPosterLink
                  project={project}
                  dueDate={formatDate(config?.dueDates?.posterLinkFinal)}
                  showLink={false}
                />
              </Paper>
            </Grid>

            {/*Inner Grid Item 5: Poster Station */}
            <Grid item lg={6} md={12} sm={12} xs={12}>
              <Paper {...Styles.dashboardContentWidget()}>
                <InformStation
                  project={project}
                  flag={flag}
                  setFlag={setFlag}
                  adminMode={true}
                />
              </Paper>
            </Grid>

            {/*Inner Grid Item 5: Poster Image */}
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Paper {...Styles.dashboardContentWidget('auto')}>
                <InformPosterImage
                  project={project}
                  dueDate={formatDate(config?.dueDates?.posterImageFinal)}
                />
              </Paper>
            </Grid>

            {/*Inner Grid Item 5: Poster Image */}
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Paper {...Styles.dashboardContentWidget('auto')}>
                <AddAssignment
                  project={project?.data}
                  flag={flag}
                  setFlag={setFlag}
                />
              </Paper>
            </Grid>

            {assignments?.map((assignment, index) => (
              <Grid item lg={12} md={12} sm={12} xs={12} key={index}>
                <Paper {...Styles.dashboardContentWidget('auto')}>
                  <DeleteableRubric
                    assignment={assignment}
                    flag={flag}
                    setFlag={setFlag}
                  />
                </Paper>
              </Grid>
            ))}

            {/*End of Inner Grid*/}
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default function AdminDashboardProject() {
  return <Dashboard Page={ExistingProjectDash} />;
}

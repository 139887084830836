import { Box, Container, Grid, Paper, Toolbar } from '@mui/material';
import Dashboard from '../../../components/Admin/Dashboard';
import { Styles } from '../../../styles/global';
import Report from '../../../components/Reports';

function Space({ num = 2 }) {
  return (
    <Grid item lg={num} sm={0} xs={0}>
      <div />
    </Grid>
  );
}

function Reports() {
  return (
    <>
      <Box {...Styles.mainBox}>
        <Toolbar />

        {/* Container With Max Width = lg breakpoint */}
        <Container {...Styles.customContainer('xl')}>
          {/*Outer Grid Container */}
          <Grid container spacing={3}>
            {/*Inner Grid Begins*/}

            <Space />

            {/*Inner Grid Item 1: Page Title */}
            <Grid item lg={8} md={12} xs={12}>
              <Paper {...Styles.dashboardPageWidget()}> Reports </Paper>
            </Grid>

            <Space />

            <Space />

            {/*Inner Grid Item 2: SCORES */}
            <Grid item lg={8} md={12} xs={12}>
              <Report
                title="Scores"
                fieldsList={['course', 'department', 'year', 'status', 'type']}
                reportType="SCORES"
              />
            </Grid>

            <Space />

            <Space />

            {/*Inner Grid Item 3: STATIONS */}
            <Grid item lg={8} md={12} xs={12}>
              <Report
                title="Stations"
                fieldsList={['course', 'department', 'year']}
                reportType="STATIONS"
              />
            </Grid>
            <Space />

            <Space />

            {/*Inner Grid Item 4: PROJECTS */}
            <Grid item lg={8} md={12} xs={12}>
              <Report
                title="Projects"
                fieldsList={['course', 'department', 'year']}
                reportType="PROJECTS"
              />
            </Grid>

            <Space />

            <Space />

            {/*Inner Grid Item 5: USER */}
            <Grid item lg={8} md={12} xs={12}>
              <Report
                title="Users"
                fieldsList={['course', 'department', 'year', 'role']}
                reportType="USERS"
              />
            </Grid>

            <Space />

            <Space />

            {/*Inner Grid Item 6: JUDGES */}
            <Grid item lg={8} md={12} xs={12}>
              <Report
                title="Judges"
                fieldsList={['year']}
                reportType="JUDGES"
              />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
}

export default function AdminDashboardReports() {
  return <Dashboard Page={Reports} />;
}

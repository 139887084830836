import * as React from 'react';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Dashboard from '../../../components/Student/Dashboard.js';
import { useState, useEffect } from 'react';
import { useAuth } from '../../../utils/AuthContext.js';
import { useNavigate, useParams } from 'react-router-dom';
import { Styles, StylesForm } from '../../../styles/global.js';
import { getMainConfig, getProject } from '../../../utils/API.js';
import {
  InformProjectAbstract,
  InformPosterImage,
  InformPosterLink,
  InformStation,
  InformProjectMembers,
  InformProjectDetails,
  InformTimeline,
  InformInbox,
} from '../../../components/Student/Widgets';

import {
  Alert,
  Checkbox,
  TextField,
  Typography,
  Divider,
  Button,
} from '@mui/material';
import { formatDate } from '../../../utils/dates.js';

// From to capture Project TITLE & DESCRIPTION
const ProjectIdentity = ({ form, setForm }) => {
  return (
    <>
      {/* Project Identity Title */}
      <Typography {...StylesForm.text('h6')}>Project Identity</Typography>
      <Divider />

      {/* Start of Form: Project & Description Names */}
      <>
        <TextField
          {...StylesForm.input('projectName', 'Project Tilte')}
          value={form.title}
          onChange={(e) => setForm({ ...form, title: e.target.value })}
        />

        <TextField
          {...StylesForm.input(
            'projectDescription',
            'Project Description (One Sentence - Two Sentences)',
            true,
            true
          )}
          InputProps={{
            rows: 6,
            multiline: true,
            inputComponent: 'textarea',
            maxLength: 600,
          }}
          value={form.description}
          onChange={(e) => setForm({ ...form, description: e.target.value })}
        />
      </>
      {/* End of Form: Project & Description Names */}
    </>
  );
};

// Form to capture CLIENT && INSTRUCTOR NAMES
const ProjectDetails = ({ form, setForm }) => {
  return (
    <>
      {/* Project Details Title */}
      <Typography {...StylesForm.text('h6')}>Project Details</Typography>

      <Divider />

      {/* Start of Form: Client & Instructor Names */}
      <>
        <TextField
          {...StylesForm.input(
            'clientNames',
            'Client(s) Names (First Last, ... , First Last)'
          )}
          value={form.clients}
          onChange={(e) => setForm({ ...form, clients: e.target.value })}
        />

        <TextField
          {...StylesForm.input(
            'instructorNames',
            "Instructor(s) Names (First Last, ... , First Last)'"
          )}
          InputProps={{
            rows: 5,
            multiline: true,
            inputComponent: 'textarea',
            maxLength: 600,
          }}
          value={form.instructor}
          onChange={(e) => setForm({ ...form, instructor: e.target.value })}
        />
      </>
      {/* End of Form: Client & Instructor Names */}
    </>
  );
};

// Student Details Widget (To confirm)
const StudentDetails = ({ form, setForm }) => {
  const { user } = useAuth();
  return (
    <>
      {/* Title of Widget */}
      <Typography {...StylesForm.text('h6')}>Student Details</Typography>

      <Divider />

      {/* Beginning of Student Details*/}
      <Box display={'flex'} flexDirection={'column'}>
        <Typography {...StylesForm.text('p')}>
          Course Equivalent : <strong>{`${user.course}`}</strong>
        </Typography>

        <Typography {...StylesForm.text('p')}>
          Department : <strong>{`${user.department}`}</strong>
        </Typography>

        <Typography {...StylesForm.text('p')}>
          Competition Participant Year : <strong>{`${user.year}`}</strong>
        </Typography>
      </Box>
      {/* End of Student Details */}

      <Divider />

      {/* Student Detail Accuracy Information */}
      <Typography {...StylesForm.text('p')}>
        Ensure your student details are accurate. Otherwise, contact an
        adminstrator to update your student information prior to project
        creation.
      </Typography>

      <Divider />

      {/* Accuracy Check Box  */}
      <Box display="flex" alignItems="center">
        <Checkbox
          checked={form.checkBox}
          onChange={(e) => setForm({ ...form, checkBox: e.target.checked })}
        />

        <p>* The information is accurate</p>
      </Box>
    </>
  );
};

// Project Creation Submission
const ProjectNotice = ({ form }) => {
  const { createProjectError, createProject } = useAuth();
  const navigate = useNavigate();

  // Handles Creation of Project. If success: navigate to project dashboard of new project.
  const handleCreation = async () => {
    const u = await createProject(form);
    console.log(u);
    if (u) {
      if (u?.data?.success) {
        navigate(`/student/dashboard/project/${u.data.data._id}`);
        return;
      }
      return;
    }
  };

  // Utility function to toggle button
  const invalidInput = () => {
    const temp = { ...form };

    if (
      Object.values(temp).some(
        (value) => typeof value === 'string' && value.length > 10000
      )
    ) {
      return true;
    }

    if (
      Object.values(temp).some(
        (value) => typeof value === 'string' && value.length === 0
      )
    ) {
      return true;
    }

    if (!form.checkBox) {
      return true;
    }
  };

  return (
    <>
      {/* Title: Project Notice */}
      <Typography {...StylesForm.text('h6')}>Project Notice</Typography>

      <Divider />

      {/* Remember to invite team members notice */}
      <Typography {...StylesForm.text('p')}>
        Remember to invite your team members to this project later in the
        invites page. Also, only one member needs to fill out this form!
      </Typography>

      <Divider />

      {/* Create Project Button */}
      <Button
        variant="outlined"
        size="large"
        disabled={invalidInput()}
        onClick={handleCreation}
        sx={{
          p: 2,
          m: 1,
        }}
      >
        Create Project
      </Button>

      <Divider />

      {/* Alert Status for Project Creatiion Request */}
      {createProjectError && (
        <Alert
          variant="outlined"
          severity="error"
          sx={{
            p: 1,
            m: 1,
          }}
        >
          {createProjectError}
        </Alert>
      )}
    </>
  );
};

// Page that shows when a project is already created
const ExistingProjectDash = () => {
  const [project, setProject] = useState(null);
  const [config, setConfig] = useState(null);
  const { pid } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getProject(pid);
        setProject(response.data);
        const configResponse = await getMainConfig();
        setConfig(configResponse?.data?.data);
      } catch (error) {
        navigate('/student/dashboard/project/0');
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [pid, navigate]);

  return (
    <>
      <Box {...Styles.mainBox}>
        <Toolbar />

        {/* Container With Max Width = lg breakpoint */}
        <Container {...Styles.customContainer()}>
          {/*Outer Grid Container */}
          <Grid container spacing={3}>
            {/*Inner Grid Begins*/}

            {/*Inner Grid Item 1: Page Title */}
            <Grid item lg={12} sm={12} xs={12}>
              <Paper {...Styles.dashboardPageWidget()}>Project Dashboard</Paper>
            </Grid>

            {/*Inner Grid Item 2: Inform Project Details */}
            <Grid item lg={6} md={12} sm={12} xs={12}>
              <Paper {...Styles.dashboardContentWidget()}>
                <InformProjectDetails project={project} />
              </Paper>
            </Grid>

            {/*Inner Grid Item 3: Project Members */}
            <Grid item lg={6} md={12} sm={12} xs={12}>
              <Paper {...Styles.dashboardContentWidget()}>
                <InformProjectMembers project={project} showLink={true} />
              </Paper>
            </Grid>

            {/*Inner Grid Item 3b: Timeline + Steps */}
            <Grid item lg={112} md={12} sm={12} xs={12}>
              <Paper {...Styles.dashboardContentWidget('auto')}>
                <InformTimeline
                  project={project}
                  dueDates={config?.dueDates}
                  showLink={true}
                />
              </Paper>
            </Grid>

            {/*Inner Grid Item 3c: Project Inbox */}

            <Grid item lg={4} md={12} sm={12} xs={12}>
              <Paper {...Styles.dashboardContentWidget()}>
                <InformInbox project={project?.data} />
              </Paper>
            </Grid>

            {/*Inner Grid Item 4: Project Abstract */}
            <Grid item lg={8} md={12} sm={12} xs={12}>
              <Paper {...Styles.dashboardContentWidget()}>
                <InformProjectAbstract
                  project={project}
                  dueDate={formatDate(config?.dueDates?.abstract)}
                  showLink={true}
                />
              </Paper>
            </Grid>

            {/*Inner Grid Item 5: Poster Link */}
            <Grid item lg={6} md={12} sm={12} xs={12}>
              <Paper {...Styles.dashboardContentWidget()}>
                <InformPosterLink
                  project={project}
                  dueDate={formatDate(config?.dueDates?.posterLinkFinal)}
                  showLink={true}
                />
              </Paper>
            </Grid>

            {/*Inner Grid Item 5: Poster Station */}
            <Grid item lg={6} md={12} sm={12} xs={12}>
              <Paper {...Styles.dashboardContentWidget()}>
                <InformStation project={project} />
              </Paper>
            </Grid>

            {/*Inner Grid Item 5: Poster Image */}
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Paper {...Styles.dashboardContentWidget('auto')}>
                <InformPosterImage
                  project={project}
                  dueDate={formatDate(config?.dueDates?.posterImageFinal)}
                />
              </Paper>
            </Grid>

            {/*End of Inner Grid*/}
          </Grid>
        </Container>
      </Box>
    </>
  );
};

// Page that shows when a new project is being created
const NewProjectDash = () => {
  const { user } = useAuth();
  const [form, setForm] = useState({
    title: '',
    description: '',
    clients: '',
    department: user.department,
    course: user.course,
    instructor: '',
    year: user.year,
    checkBox: false,
  });
  return (
    <>
      <Box {...Styles.mainBox}>
        <Toolbar />

        {/* Container With Max Width = lg breakpoint */}
        <Container {...Styles.customContainer()}>
          {/*Outer Grid Container */}
          <Grid container spacing={4}>
            {/*Inner Grid Begins*/}

            {/*Inner Grid Item 1: Page Title */}
            <Grid item lg={12} sm={12} xs={12}>
              <Paper {...Styles.dashboardPageWidget()}>
                Project Creation Form
              </Paper>
            </Grid>

            {/*Inner Grid Item 2: Project Identity*/}
            <Grid item lg={6} sm={12} xs={12}>
              <Paper {...Styles.dashboardContentWidget()}>
                <ProjectIdentity form={form} setForm={setForm} />
              </Paper>
            </Grid>

            {/*Inner Grid Item 3: Project Details*/}
            <Grid item lg={6} sm={12} xs={12}>
              <Paper {...Styles.dashboardContentWidget()}>
                <ProjectDetails form={form} setForm={setForm} />
              </Paper>
            </Grid>

            {/*Inner Grid Item 4: Student Details*/}
            <Grid item lg={6} sm={12} xs={12}>
              <Paper {...Styles.dashboardContentWidget()}>
                <StudentDetails form={form} setForm={setForm} />
              </Paper>
            </Grid>

            {/*Inner Grid Item 5: Project Notice*/}
            <Grid item lg={6} sm={12} xs={12}>
              <Paper {...Styles.dashboardContentWidget()}>
                <ProjectNotice form={form} setForm={setForm} />
              </Paper>
            </Grid>

            {/*End Of Inner Grid*/}
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default function ProjectDashboard() {
  const { pid } = useParams();

  return Number(pid) !== 0 ? (
    <Dashboard Page={ExistingProjectDash} />
  ) : (
    <Dashboard Page={NewProjectDash} />
  );
}

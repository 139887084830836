import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import FilterComponent from './helpers/fields';
import { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
const Filters = ({ filtersSchema }) => {
  const [queryParams] = useSearchParams();
  const navigate = useNavigate();
  const [form, setForm] = useState(
    Object.keys(filtersSchema).reduce((acc, section) => {
      acc[section] = queryParams.get(section) || '';
      return acc;
    }, {})
  );
  const handleChange = (e) => {
    const newForm = { ...form, [e.target.name]: e.target.value };
    setForm(newForm);
    const filteredForm = Object.keys(newForm).reduce((acc, key) => {
      if (newForm[key] && newForm[key].length > 0) {
        acc[key] = newForm[key];
      }
      return acc;
    }, {});

    navigate({
      search: new URLSearchParams(filteredForm).toString(),
    });
  };

  return (
    <div>
      {Object.keys(filtersSchema).map((section) => {
        return (
          <Accordion key={section} variant="outlined" defaultExpanded>
            <AccordionSummary>
              {filtersSchema[section].title.value.toUpperCase()}
            </AccordionSummary>
            <AccordionDetails>
              <FilterComponent
                name={section}
                field={filtersSchema[section].fields}
                handleChange={handleChange}
                value={form[section]}
              />
            </AccordionDetails>
          </Accordion>
        );
      })}
    </div>
  );
};

export default Filters;

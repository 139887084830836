import * as React from 'react';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Dashboard from '../../../components/Student/Dashboard.js';
import { useState, useEffect } from 'react';
import { useAuth } from '../../../utils/AuthContext.js';
import { useNavigate, useParams, Link } from 'react-router-dom';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

import { Alert, TextField, Typography, Divider, Button } from '@mui/material';
import { Styles, StylesForm } from '../../../styles/global.js';
import {
  getMainConfig,
  getProject,
  updatePosterImage,
  updatePosterLink,
} from '../../../utils/API.js';
import { formatDate } from '../../../utils/dates.js';
import {
  InformPosterLink,
  InformPosterImage,
} from '../../../components/Student/Widgets';

const determineDate = (draft, final) => {
  if (new Date(draft) > new Date()) {
    return draft;
  } else {
    return final;
  }
};

// Form for Submitting Poster Link
const SubmitPosterLink = ({ setFeedback, setProject }) => {
  const { user } = useAuth();
  const { pid } = useParams();
  const [url, setUrl] = useState('');

  const [destinations, setDestinations] = useState();

  useEffect(() => {
    const fetchDestinations = async () => {
      try {
        const response = await getMainConfig();
        setDestinations(
          new Map(response?.data?.data?.googleDriveFolders || [])
        );
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchDestinations();
  }, []);

  const handleSubmission = async () => {
    try {
      const response = await updatePosterLink(pid, url);

      if (response.data.success) {
        setFeedback({ severity: 'success', message: response.data.message });
        try {
          const response = await getProject(pid);
          setProject(response.data);
          setUrl('');
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setFeedback({
        severity: 'error',
        message: error.response.data.errMessage,
      });
    }
  };

  return (
    <>
      <>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          {/* Title: Submit Poster Link */}
          <Typography variant="h6" margin={1}>
            Submit Poster Link
          </Typography>

          <Divider />

          {/* Button: Submits Poster Link */}
          <Button
            variant="outlined"
            disabled={url.length === 0}
            onClick={handleSubmission}
            sx={{ marginBottom: 1 }}
          >
            Submit
          </Button>
        </Box>

        <Divider />

        {/* Code for Directions + Designated Folder */}
        <>
          {/* Input: Poster Link */}
          <TextField
            name="submitPosterLink"
            required
            fullWidth
            id="submitPosterLink"
            label="URL To Your Poster"
            InputProps={{
              rows: 1,
              maxLength: 600,
            }}
            value={url}
            onChange={(e) => setUrl(e.target.value)}
            sx={{ marginTop: 2, marginBottom: 2 }}
          />
          <Divider />
          {/* Title: Directions */}
          <Typography variant="h6" margin={1}>
            Directions
          </Typography>
          {/* Body: Directions */}
          <Typography variant="p" marginLeft={1}>
            Go to your designated Google Drive Shared Folder and Drop the Poster
            there. Then, grab the share link and submit it here.
          </Typography>
          {/* Link to Designated Drive based on Major + Course */}
          {destinations && (
            <Link
              to={destinations.get(`${user.department} ${user.course}`)}
              target="_blank"
              style={{ marginTop: 15, marginLeft: 7 }}
            >
              {' '}
              Your Designated Google Drive Folder
            </Link>
          )}
        </>
      </>
    </>
  );
};

// Form for Submtting Poster Image
const SubmitPosterImage = ({ setFeedback, setProject }) => {
  const [file, setFile] = useState(null);
  const { pid } = useParams();

  const handleSubmission = async () => {
    const formData = new FormData();
    formData.append('image', file);
    try {
      const response = await updatePosterImage(pid, formData);

      if (response.data.success) {
        setFeedback({ severity: 'success', message: response.data.message });
        try {
          const response = await getProject(pid);
          setProject(response.data);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setFeedback({
        severity: 'error',
        message: error.response.data.errMessage,
      });
    }
  };

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        {/* Title: Submit Poster Image */}
        <Typography variant="h6" margin={1}>
          Submit Poster Image
        </Typography>

        <Divider />

        {/* Button: Submit Image */}
        <Button
          variant="outlined"
          sx={{ marginBottom: 1 }}
          disabled={!file}
          onClick={handleSubmission}
        >
          Submit
        </Button>
      </Box>

      <Divider />

      {/* Upload Button */}
      <Box
        display={'flex'}
        alignItems={'center'}
        justifyContent={'center'}
        flexDirection={'column'}
        sx={{
          height: '100%',
        }}
      >
        <label htmlFor="contained-button-file">
          <input
            accept="image/*"
            id="contained-button-file"
            multiple
            type="file"
            style={{ display: 'none' }}
            onChange={handleFileChange}
          />
          <Button
            variant="contained"
            component="span"
            startIcon={<CloudUploadIcon />}
            size="large"
          >
            Upload
          </Button>
        </label>
        <Typography
          variant="p"
          margin={1}
          sx={{
            color: '#1976D2',
          }}
        >
          {file ? file.name : 'File Not Submitted'}
        </Typography>
        <Divider />
      </Box>
    </>
  );
};

// Widget to Feedback Inform
const InformFeedback = ({ feedback }) => {
  return (
    <>
      {/* Title: Submission Status */}
      <Typography variant="h6" margin={1}>
        Submission Status
      </Typography>

      <Divider />

      {/* Content: Feedback */}
      {feedback ? (
        <Alert severity={feedback.severity}> {feedback.message} </Alert>
      ) : (
        <></>
      )}
    </>
  );
};
//
const InformTemplates = () => {
  const [templates, setTemplates] = useState(null);
  const { user } = useAuth();
  useEffect(() => {
    const fetchTemplates = async () => {
      try {
        const response = await getMainConfig();
        setTemplates(response?.data?.data?.posterTemplatesLinks || []);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchTemplates();
  }, []);

  return (
    <>
      {/* Title: Poster Templates */}
      <Typography variant="h6" margin={1}>
        Poster Templates
      </Typography>

      <Divider />

      {/* Body: Descriptions */}
      <Typography
        variant="p"
        margin={1}
        sx={{
          marginBottom: 2,
        }}
      >
        You may use the poster template links below to help you create your own
        poster.
      </Typography>
      {/* Title: Links */}
      <Typography variant="h6" margin={1}>
        Link Based on your Major and Course
      </Typography>
      <Divider />

      {/* LIST: Template Links */}
      {templates &&
        templates
          .filter(
            (template) =>
              template.name ===
              `${user?.department} POSTER TEMPLATE (${user?.course})`
          )
          .map((template) => (
            <Box key={template.name + 'box'} display={'flex'}>
              <Link
                key={template.name}
                to={template.link}
                target="_blank"
                style={{
                  padding: '3px',
                }}
              >
                {template.name}
              </Link>
            </Box>
          ))}
    </>
  );
};

// Widget for General Poster Information
const InformPoster = () => {
  return (
    <>
      {/* Title: Poster Information */}
      <Typography variant="h6" margin={1}>
        Poster Information
      </Typography>

      <Divider />

      {/* Paragraph: Poster Information Details */}
      <Typography
        variant="p"
        margin={1}
        sx={{
          marginBottom: 2,
        }}
      >
        The poster is used to showcase the project on Senior Design Day.
      </Typography>

      {/* Title: Poster Deliverables */}
      <Typography variant="h6" margin={1}>
        Poster Deliverables
      </Typography>

      <Divider />

      {/* LIST: Deliverables */}

      <ul style={{ marginTop: 4 }}>
        <li>
          A link to a pdf of your poster in the shared Senior Design Day
          Department Google Drive Folder.
        </li>
        <li>
          A downloaded image from your google slide poster with a .jpg
          extention.
        </li>
      </ul>
    </>
  );
};

// Widget for No Project Selected Notice
const InformNull = () => {
  return (
    <>
      {/* Title: No Project Notice */}
      <Typography variant="h6" margin={1}>
        No Project Selected Notice
      </Typography>

      <Divider />

      {/* Paragraph: No Selected Project */}
      <Typography
        {...StylesForm.text('p')}
        sx={{
          marginBottom: 1,
        }}
      >
        No project is currently selected.
      </Typography>

      {/* Due Date */}
      <Typography
        {...StylesForm.text('p')}
        sx={{
          marginBottom: 3,
          color: '#226E93',
        }}
      >
        Please select a project to submit a poster.
      </Typography>

      {/* Title: Resolution Options*/}
      <>
        <Typography variant="h6" margin={1}>
          Options for Resolution
        </Typography>
        <Divider />
        <ul style={{ marginTop: 4 }}>
          <li>Create a Project in the Dashboard Page.</li>
          <li>Select an Existing Project from the Project Dropdown Menu.</li>
        </ul>
      </>
    </>
  );
};

// Page that shows when PID != 0
const ExistingProjectPoster = () => {
  const [feedback, setFeedback] = useState(null);
  const [project, setProject] = useState(null);
  const [config, setConfig] = useState(null);
  const { pid } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getProject(pid);
        setProject(response.data);
        const configResponse = await getMainConfig();
        setConfig(configResponse?.data?.data);
      } catch (error) {
        navigate('/student/dashboard/project/0');
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, [navigate, pid]);

  return (
    <>
      <Box {...Styles.mainBox}>
        <Toolbar />

        {/* Container With Max Width = lg breakpoint */}
        <Container {...Styles.customContainer()}>
          {/*Outer Grid Container */}

          <Grid container spacing={4}>
            {/*Inner Grid Begins*/}
            {/*Inner Grid Item 1: Project Abstract Form Title*/}
            <Grid item lg={12} sm={12} xs={12}>
              <Paper {...Styles.dashboardPageWidget()}>
                Project Poster Submission
              </Paper>
            </Grid>

            {/*Inner Grid Item 2: Poster Template Links*/}
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Paper {...Styles.dashboardContentWidget('auto')}>
                <InformTemplates />
              </Paper>
            </Grid>

            {/*Inner Grid Item 3: General Poster Information*/}
            <Grid item lg={5} md={12} sm={12} xs={12}>
              <Paper {...Styles.dashboardContentWidget()}>
                <InformPoster />
              </Paper>
            </Grid>

            {/*Inner Grid Item 4: DISPLAY POSTER LINK*/}
            <Grid item lg={7} md={12} sm={12} xs={12}>
              <Paper {...Styles.dashboardContentWidget()}>
                <InformPosterLink
                  project={project}
                  setProject={setProject}
                  feedback={feedback}
                  setFeedback={setFeedback}
                  dueDate={formatDate(
                    determineDate(
                      config?.dueDates?.posterLink,
                      config?.dueDates?.posterLinkFinal
                    )
                  )}
                />
              </Paper>
            </Grid>

            {/*Inner Grid Item 5: FEEDBACK*/}
            <Grid item lg={5} md={12} sm={12} xs={12}>
              <Paper {...Styles.dashboardContentWidget()}>
                <InformFeedback feedback={feedback} />
              </Paper>
            </Grid>

            {/*Inner Grid Item 6: SUBMIT POSTER LINK*/}
            <Grid item lg={7} md={12} sm={12} xs={12}>
              <Paper {...Styles.dashboardContentWidget()}>
                <SubmitPosterLink
                  project={project}
                  setProject={setProject}
                  feedback={feedback}
                  setFeedback={setFeedback}
                />
              </Paper>
            </Grid>

            {/*Inner Grid Item 8: SUBMIT POSTER IMAGE*/}
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Paper {...Styles.dashboardContentWidget()}>
                <SubmitPosterImage
                  project={project}
                  setFeedback={setFeedback}
                  setProject={setProject}
                />
              </Paper>
            </Grid>

            {/*Inner Grid Item 7: DISPLAY POSTER IMAGE*/}
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Paper {...Styles.dashboardContentWidget('auto')}>
                <InformPosterImage
                  project={project}
                  setProject={setProject}
                  dueDate={formatDate(
                    determineDate(
                      config?.dueDates?.posterImage,
                      config?.dueDates?.posterImageFinal
                    )
                  )}
                />
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

// Page that shows when PID == 0
const NewProjectPoster = () => {
  return (
    <>
      <Box {...Styles.mainBox}>
        <Toolbar />

        {/* Container With Max Width = lg breakpoint */}
        <Container {...Styles.customContainer()}>
          {/*Outer Grid Container */}

          <Grid container spacing={4}>
            {/*Inner Grid Begins*/}
            {/*Inner Grid Item 1: Project Abstract Form Title*/}
            <Grid item lg={12} sm={12} xs={12}>
              <Paper {...Styles.dashboardPageWidget()}>
                Project Poster Submission
              </Paper>
            </Grid>

            {/*Inner Grid Item 2: No Project Selected Notice*/}
            <Grid item lg={6} md={12} sm={12} xs={12}>
              <Paper {...Styles.dashboardContentWidget()}>
                <InformNull />
              </Paper>
            </Grid>

            {/*Inner Grid Item 3: General Poster Information*/}
            <Grid item lg={6} md={12} sm={12} xs={12}>
              <Paper {...Styles.dashboardContentWidget()}>
                <InformPoster />
              </Paper>
            </Grid>

            {/*Inner Grid Item 4: Poster Template Links*/}
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Paper {...Styles.dashboardContentWidget('auto')}>
                <InformTemplates />
              </Paper>
            </Grid>
            {/* Inner Grid Ends */}
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default function ProjectPoster() {
  const { pid } = useParams();

  return Number(pid) !== 0 ? (
    <Dashboard Page={ExistingProjectPoster} PageName="Poster" />
  ) : (
    <Dashboard Page={NewProjectPoster} PageName="Poster" />
  );
}

import * as React from 'react';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Dashboard from '../../../../components/Judge/Dashboard.js';
import { useState, useEffect } from 'react';
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { Styles } from '../../../../styles/global.js';
import {
  getAssignments,
  getMainConfig,
  getProject,
} from '../../../../utils/API.js';
import {
  InformProjectAbstract,
  InformPosterImage,
  InformPosterLink,
  InformStation,
  InformProjectMembers,
  InformProjectDetails,
} from '../../../../components/Student/Widgets/index.js';

import { formatDate } from '../../../../utils/dates.js';
import { Breadcrumbs, Typography } from '@mui/material';
import ScorableRubric from '../../../../components/Rubrics/ScorableRubric.js';

const Assignment = () => {
  const [project, setProject] = useState(null);
  const [config, setConfig] = useState(null);
  const [assignments, setAssignments] = useState([]);
  const { aid } = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [flag, setFlag] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getProject(aid);
        setProject(response.data);
        const configResponse = await getMainConfig();
        setConfig(configResponse?.data?.data);
        const responseAssignments = await getAssignments(aid);
        setAssignments(responseAssignments?.data?.data);
      } catch (error) {
        navigate('/');
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [aid, navigate, flag]);

  return (
    <>
      <Box {...Styles.mainBox}>
        <Toolbar />

        {/* Container With Max Width = lg breakpoint */}
        <Container {...Styles.customContainer()}>
          {/*Outer Grid Container */}
          <Grid container spacing={3}>
            {/*Inner Grid Begins*/}

            {/*Inner Grid Item 0: BreadCrumbs */}
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Box>
                <Breadcrumbs aria-label="breadcrumb">
                  <Link to={`/judge/dashboard?${searchParams.toString()}`}>
                    Dashboard
                  </Link>
                  <Link
                    to={`/judge/dashboard/assignments?${searchParams.toString()}`}
                  >
                    Assignments
                  </Link>
                  <Typography color="textPrimary">Assignment</Typography>
                </Breadcrumbs>
              </Box>
            </Grid>

            {/*Inner Grid Item 1: Page Title */}
            <Grid item lg={12} sm={12} xs={12}>
              <Paper {...Styles.dashboardPageWidget()}>
                Project Dashboard View
              </Paper>
            </Grid>

            {/*Inner Grid Item 2: Inform Project Details */}
            <Grid item lg={6} md={12} sm={12} xs={12}>
              <Paper {...Styles.dashboardContentWidget()}>
                <InformProjectDetails project={project} />
              </Paper>
            </Grid>

            {/*Inner Grid Item 3: Project Members */}
            <Grid item lg={6} md={12} sm={12} xs={12}>
              <Paper {...Styles.dashboardContentWidget()}>
                <InformProjectMembers project={project} showLink={false} />
              </Paper>
            </Grid>

            {/*Inner Grid Item 4: Project Abstract */}
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Paper {...Styles.dashboardContentWidget()}>
                <InformProjectAbstract
                  project={project}
                  dueDate={formatDate(config?.dueDates?.abstract)}
                  showLink={false}
                />
              </Paper>
            </Grid>

            {/*Inner Grid Item 5: Poster Link */}
            <Grid item lg={6} md={12} sm={12} xs={12}>
              <Paper {...Styles.dashboardContentWidget()}>
                <InformPosterLink
                  project={project}
                  dueDate={formatDate(config?.dueDates?.posterLinkFinal)}
                  showLink={false}
                />
              </Paper>
            </Grid>

            {/*Inner Grid Item 5: Poster Station */}
            <Grid item lg={6} md={12} sm={12} xs={12}>
              <Paper {...Styles.dashboardContentWidget()}>
                <InformStation project={project} />
              </Paper>
            </Grid>

            {/*Inner Grid Item 5: Poster Image */}
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Paper {...Styles.dashboardContentWidget('auto')}>
                <InformPosterImage
                  project={project}
                  dueDate={formatDate(config?.dueDates?.posterImageFinal)}
                />
              </Paper>
            </Grid>

            {assignments?.length > 0 &&
              assignments.map((assignment) => (
                <Grid
                  item
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  key={assignment?._id}
                >
                  <Paper {...Styles.dashboardContentWidget('auto')}>
                    <ScorableRubric
                      assignment={assignment}
                      flag={flag}
                      setFlag={setFlag}
                    />
                  </Paper>
                </Grid>
              ))}

            {/*End of Inner Grid*/}
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default function JudgeDashboardAssignment() {
  return <Dashboard Page={Assignment} />;
}

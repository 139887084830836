import { Box, Input, Alert } from '@mui/material';
import { useState } from 'react';

export default function ListA({ component, handleChange }) {
  // make it join the array with a comma and a space
  const [localContent, setLocalContent] = useState(
    component?.items?.join(', ') || ''
  );

  function handleContentChange() {
    const split = localContent.split(',').map((item) => item.trim());
    const newComponent = { ...component, items: split };
    handleChange(newComponent);
  }
  return (
    <Box display="flex" flexDirection={'column'} gap={2}>
      <h5>List</h5>
      <Input
        value={localContent}
        fullWidth
        multiline
        onChange={(e) => setLocalContent(e.target.value)}
        onBlur={() => handleContentChange()}
        placeholder="Input Must Be In Comma Separated Values"
      />
      <Alert variant="info">
        Input Must Be In Comma Separated Values. CSV as Delimeter
      </Alert>
    </Box>
  );
}

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  tableCellClasses,
  styled,
  Input,
} from '@mui/material';
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#424242',
    color: '#f5f5f5',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: '#e0e0e0',
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export default function ViewRubric({ rubric, scores, handleScoreChange }) {
  return (
    rubric && (
      <TableContainer
        component={Paper}
        color="primary"
        sx={{
          boxShadow: 8,
          whiteSpace: 'pre-wrap',
          wordWrap: 'break-word',
        }}
      >
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <StyledTableCell></StyledTableCell>
              <StyledTableCell sx={{ fontWeight: 600 }}>
                Weights
              </StyledTableCell>
              {rubric?.scoreCategories.map((scoreCategory, index) => (
                <StyledTableCell key={index} sx={{ fontWeight: 600 }}>
                  {scoreCategory}
                </StyledTableCell>
              ))}

              {scores?.length > 0 && (
                <StyledTableCell sx={{ fontWeight: 600 }}>
                  Score
                </StyledTableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {rubric?.rubricCategories.map((rubricCategory, rubricIndex) => (
              <StyledTableRow key={rubricIndex}>
                <StyledTableCell>{rubricCategory}</StyledTableCell>
                <StyledTableCell>{rubric.weights[rubricIndex]}</StyledTableCell>
                {rubric.scoreCategories.map((scoreCategory, scoreIndex) => (
                  <StyledTableCell key={rubricIndex + ' ' + scoreIndex}>
                    {
                      rubric.scoreCategoriesDescriptions[rubricIndex][
                        scoreIndex
                      ]
                    }
                  </StyledTableCell>
                ))}
                {!handleScoreChange && scores?.length > 0 && (
                  <StyledTableCell>
                    {scores[rubricIndex] !== undefined
                      ? scores[rubricIndex]
                      : 'N/A'}
                  </StyledTableCell>
                )}
                {!!handleScoreChange && scores?.length > 0 && (
                  <StyledTableCell>
                    <Input
                      value={scores[rubricIndex]}
                      type="number"
                      onChange={(e) =>
                        handleScoreChange(rubricIndex, e.target.value)
                      }
                      sx={{
                        width: '50px',
                      }}
                    />
                  </StyledTableCell>
                )}
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    )
  );
}

import * as React from 'react';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Dashboard from '../../../components/Student/Dashboard.js';
import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Styles, StylesForm } from '../../../styles/global.js';
import {
  getProject,
  getStudentsOptions,
  inviteStudent,
} from '../../../utils/API.js';

import {
  Alert,
  TextField,
  Typography,
  Divider,
  Button,
  Autocomplete,
} from '@mui/material';
import { InformProjectMembers } from '../../../components/Student/Widgets';

// From to invite members
const SubmitInvites = ({ setFeedback, setProject }) => {
  const { pid } = useParams();
  const [invite, setInvite] = useState('');
  const [students, setStudents] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getStudentsOptions();
        setStudents(response?.data?.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [pid]);

  const handleSubmission = async () => {
    try {
      const response = await inviteStudent(pid, invite);

      if (response?.data?.success) {
        setFeedback({ severity: 'success', message: response.data.message });
        try {
          const response = await getProject(pid);
          setProject(response.data);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setFeedback({
        severity: 'error',
        message: error.response.data.errMessage,
      });
    }
  };

  return (
    <>
      <>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          {/* Title: Invite Member Form */}
          <Typography variant="h6" margin={1}>
            Invite Member Form
          </Typography>

          <Divider />

          {/* Submit Form */}
          <Button
            variant="outlined"
            disabled={invite.length === 0}
            onClick={handleSubmission}
            sx={{ marginBottom: 1 }}
          >
            Submit
          </Button>
        </Box>

        <Divider />

        {/* Start of Form: Email */}
        <Autocomplete
          disablePortal
          id="combo-box-demo"
          options={students}
          sx={{ width: '100%', my: 2 }}
          renderInput={(params) => (
            <TextField {...params} label="Students from your class" />
          )}
          onChange={(event, value) => {
            if (value) {
              setInvite(value.email);
            }
          }}
        />
        {/* End of From */}
      </>
    </>
  );
};

// Widget to Feedback Inform
const InformFeedback = ({ feedback }) => {
  return (
    <>
      {/* Title: Submission Status */}
      <Typography variant="h6" margin={1}>
        Submission Status
      </Typography>

      <Divider />

      {/* Content: Feedback */}
      {feedback ? (
        <Alert severity={feedback.severity}> {feedback.message} </Alert>
      ) : (
        <></>
      )}
    </>
  );
};

// Widget for Invite Information
const InformInvites = () => {
  return (
    <>
      {/* Title: Invites Information */}
      <Typography variant="h6" margin={1}>
        Invites Information
      </Typography>

      <Divider />

      {/* Paragraph: Page Information */}
      <Typography
        {...StylesForm.text('p')}
        sx={{
          marginBottom: 2,
        }}
      >
        This page allows you to invite your project members to the project by
        the email they used to signup. Once your project members join,
        they@aposll be able to see and interact with this project.
      </Typography>

      {/* Title: Invites Requirements */}
      <Typography variant="h6" margin={1}>
        Invite Requirements
      </Typography>

      <Divider />
      {/* LISTED requirements */}
      <>
        <ul style={{ marginTop: 4 }}>
          <li>Project memebers have created a student account.</li>
          <li>Emails submitted are student accounts.</li>
        </ul>
      </>
    </>
  );
};

// Widget for No Project Selected Notice
const InformNull = () => {
  return (
    <>
      {/* Title: No Project Notice */}
      <Typography variant="h6" margin={1}>
        No Project Selected Notice
      </Typography>
      <Divider />
      {/* Paragraph: No Selected Project */}
      <Typography
        {...StylesForm.text('p')}
        sx={{
          marginBottom: 1,
        }}
      >
        No project is currently selected.
      </Typography>

      {/* Due Date */}
      <Typography
        {...StylesForm.text('p')}
        sx={{
          marginBottom: 3,
          color: '#226E93',
        }}
      >
        Please select a project to invite members.
      </Typography>
      {/* Title: Resolution Options*/}
      <>
        <Typography variant="h6" margin={1}>
          Options for Resolution
        </Typography>
        <Divider />
        <ul style={{ marginTop: 4 }}>
          <li>Create a Project in the Dashboard Page.</li>
          <li>Select an Existing Project from the Project Dropdown Menu.</li>
        </ul>
      </>
    </>
  );
};

// Page that shows when a project IS selected
const ExistingProjectInvites = () => {
  const [feedback, setFeedback] = useState(null);
  const [project, setProject] = useState(null);
  const { pid } = useParams();
  const navigate = useNavigate();

  // Grabs Project When Page Loads
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getProject(pid);
        setProject(response.data);
      } catch (error) {
        navigate('/student/dashboard/project/0/abstract');
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [navigate, pid]);

  return (
    <>
      <Box {...Styles.mainBox}>
        <Toolbar />

        {/* Container With Max Width = lg breakpoint */}
        <Container {...Styles.customContainer()}>
          {/*Outer Grid Container */}

          {/*Inner Grid Begins*/}

          {/*Inner Grid Item 1: Project Invites Form Title*/}
          <Grid container spacing={4}>
            <Grid item lg={12} sm={12} xs={12}>
              <Paper {...Styles.dashboardPageWidget()}>Project Invites</Paper>
            </Grid>

            {/*Inner Grid Item 2 Widget shows members */}
            <Grid item lg={7} md={12} sm={12} xs={12}>
              <Paper {...Styles.dashboardContentWidget()}>
                <InformProjectMembers
                  project={project}
                  setProject={setProject}
                />
              </Paper>
            </Grid>

            {/*Inner Grid Item 3 Invite Information */}
            <Grid item lg={5} md={12} sm={12} xs={12}>
              <Paper {...Styles.dashboardContentWidget()}>
                <InformInvites />
              </Paper>
            </Grid>

            <Grid item lg={7} md={12} sm={12} xs={12}>
              <Paper {...Styles.dashboardContentWidget()}>
                <SubmitInvites
                  project={project}
                  setProject={setProject}
                  feedback={feedback}
                  setFeedback={setFeedback}
                />
              </Paper>
            </Grid>

            {/* Inner Grid Item 4 Feedback Inform */}
            <Grid item lg={5} md={12} sm={12} xs={12}>
              <Paper {...Styles.dashboardContentWidget()}>
                <InformFeedback feedback={feedback} />
              </Paper>
            </Grid>

            {/*End Of Inner Grid*/}
          </Grid>
        </Container>
      </Box>
    </>
  );
};

// Page that shows when PID -- 0
const NewProjectInvites = () => {
  return (
    <>
      <Box {...Styles.mainBox}>
        <Toolbar />

        {/* Container With Max Width = lg breakpoint */}
        <Container {...Styles.customContainer()}>
          {/*Outer Grid Container */}

          <Grid container spacing={4}>
            {/*Inner Grid Begins*/}

            {/*Inner Grid Item 1: Project Invites Form Title*/}
            <Grid item lg={12} sm={12} xs={12}>
              <Paper {...Styles.dashboardPageWidget()}>
                Project Invites Form
              </Paper>
            </Grid>

            {/*Inner Grid Item 2: No Project Selected Notice*/}
            <Grid item lg={6} md={12} sm={12} xs={12}>
              <Paper {...Styles.dashboardContentWidget()}>
                <InformNull />
              </Paper>
            </Grid>

            {/*Inner Grid Item 3: Invites Information*/}
            <Grid item lg={6} md={12} sm={12} xs={12}>
              <Paper {...Styles.dashboardContentWidget()}>
                <InformInvites />
              </Paper>
            </Grid>

            {/* Inner Grid Ends */}
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default function ProjectInvites() {
  const { pid } = useParams();

  return Number(pid) !== 0 ? (
    <Dashboard Page={ExistingProjectInvites} PageName={'Invites'} />
  ) : (
    <Dashboard Page={NewProjectInvites} PageName={'Invites'} />
  );
}

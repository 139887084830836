import * as React from 'react';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Dashboard from '../../../components/Admin/Dashboard.js';
import { useState, useEffect } from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Link } from 'react-router-dom';
import { Styles } from '../../../styles/global.js';
import {
  getChatbotSummary,
  getMainConfig,
  updateKnowledgeBase,
  updateMainConfig,
} from '../../../utils/API.js';
import 'chart.js/auto';
import Modal from '../../../components/Modal.js';
import dayjs from 'dayjs';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

import {
  Alert,
  Checkbox,
  TextField,
  Typography,
  Divider,
  Button,
  AccordionDetails,
  CircularProgress,
} from '@mui/material';
import { formatDate } from '../../../utils/dates.js';

function ViewAndEditMisc({ setMainConfig, mainConfig }) {
  const [open, setOpen] = useState(false);
  const [loader, setLoader] = useState(false);
  const [chatbotSummary, setChatbotSummary] = useState(null);
  const [file, setFile] = useState(null);
  const handleSubmission = async () => {
    try {
      await updateMainConfig(mainConfig);
      alert('Updated Successfully');
    } catch (error) {
      alert('Error Updating');
      console.error(error);
    }
  };

  const handleUpdate = async () => {
    setLoader(true);
    const formData = new FormData();
    formData.append('pdf', file);
    try {
      const response = await updateKnowledgeBase(formData);

      if (response?.data?.success) {
        alert("Chatbot's knowledge base updated successfully!");
      }
      setLoader(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      alert('Error updating chatbot knowledge base!');
      setLoader(false);
    }
  };

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const openChatbotSummary = () => {
    setOpen(true);
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const chatbotSummary = await getChatbotSummary();
        setChatbotSummary(chatbotSummary?.data?.data);
      } catch (error) {
        console.error(error);
      }
    }
    fetchData();
  }, []);

  return (
    mainConfig && (
      <Box padding={2}>
        <Modal open={open} handleClose={() => setOpen(false)} cta="Close">
          <Typography variant="h6" fontWeight={'fontWeightBold'}>
            Chatbot Summary
          </Typography>

          {chatbotSummary && (
            <AccordionDetails>
              <Typography variant="body1">
                {chatbotSummary?.knowledgeBase}
              </Typography>
            </AccordionDetails>
          )}
        </Modal>
        <Box display={'flex'} justifyContent={'space-between'}>
          <Typography variant="h6" fontWeight={'fontWeightBold'}>
            Miscellaneous Configurations
          </Typography>
          <Box>
            <Button variant="text" color="primary" onClick={openChatbotSummary}>
              View Chatbot Summary
            </Button>
            <Button variant="text" color="primary" onClick={handleSubmission}>
              Save
            </Button>
          </Box>
        </Box>

        {/* Link Checking */}
        <Box
          display={'flex'}
          sx={{
            alignItems: 'center',
            justifyContent: 'space-between',
            marginY: 2,
          }}
        >
          <Typography variant="body1">
            <b>Link Checking for Poster Links</b>
          </Typography>
          {mainConfig?.misc && (
            <Checkbox
              checked={mainConfig?.misc?.linkChecking}
              onChange={(e) => {
                mainConfig.misc.linkChecking = e.target.checked;
                setMainConfig({ ...mainConfig });
              }}
            />
          )}
        </Box>

        {mainConfig?.misc?.linkChecking && (
          <Alert severity="info" sx={{ marginY: 2 }}>
            Poster Links are now being checked! They must point to a valid
            submission in a UMD Google Drive Folder.
          </Alert>
        )}

        {/* Enable ChatBot */}
        <Box
          display={'flex'}
          sx={{
            alignItems: 'center',
            justifyContent: 'space-between',
            marginY: 2,
          }}
        >
          <Typography variant="body1">
            <b>Enable Chatbot</b>
          </Typography>
          {mainConfig?.misc && (
            <Checkbox
              checked={mainConfig?.misc?.showChatBot}
              onChange={(e) => {
                mainConfig.misc.showChatBot = e.target.checked;
                setMainConfig({ ...mainConfig });
              }}
            />
          )}
        </Box>

        {mainConfig?.misc?.showChatBot && (
          <Alert severity="info" sx={{ marginY: 2 }}>
            Chatbot is now enabled and visible to home and event pages!
          </Alert>
        )}

        {/* Update ChatBot */}
        <Box
          display={'flex'}
          sx={{
            alignItems: 'center',
            justifyContent: 'space-between',
            marginY: 2,
          }}
        >
          <Typography variant="body1">
            <b>Update Chatbot</b>
          </Typography>
          <Box display={'flex'} gap={2}>
            <label htmlFor="contained-button-file">
              <input
                accept="application/pdf"
                id="contained-button-file"
                multiple
                type="file"
                style={{ display: 'none' }}
                onChange={handleFileChange}
              />
              <Button
                variant="contained"
                component="span"
                startIcon={<CloudUploadIcon />}
              >
                Upload
              </Button>
            </label>
            {!loader ? (
              <Button
                variant="contained"
                color="primary"
                onClick={handleUpdate}
                disabled={!file}
              >
                Update
              </Button>
            ) : (
              <CircularProgress />
            )}
          </Box>
        </Box>
        <Alert severity="info" sx={{ marginY: 2 }}>
          Reads PDF files and updates the chatbot knowledge base.
          {file && ' File: ' + file?.name}
        </Alert>
      </Box>
    )
  );
}

function ViewAndEditInfoBanner({ setMainConfig, mainConfig }) {
  const handleSubmission = async () => {
    try {
      await updateMainConfig(mainConfig);
      alert('Updated Successfully');
    } catch (error) {
      alert('Error Updating');
      console.error(error);
    }
  };

  return (
    mainConfig && (
      <Box padding={2}>
        <Box display={'flex'} justifyContent={'space-between'}>
          <Typography variant="h6" fontWeight={'fontWeightBold'}>
            Info Banner for Guest Pages
          </Typography>
          <Button variant="text" color="primary" onClick={handleSubmission}>
            Save
          </Button>
        </Box>
        <Box
          display={'flex'}
          sx={{
            alignItems: 'center',
            justifyContent: 'space-between',
            marginY: 2,
          }}
        >
          <Typography variant="body1">
            <b>Show Alert</b>
          </Typography>
          {mainConfig?.infoBanner && (
            <Checkbox
              checked={mainConfig?.infoBanner?.showAlert}
              onChange={(e) => {
                mainConfig.infoBanner.showAlert = e.target.checked;
                setMainConfig({ ...mainConfig });
              }}
            />
          )}
        </Box>

        {mainConfig?.infoBanner?.showAlert && (
          <Alert severity="info" sx={{ marginY: 2 }}>
            Info Banner is now visible!
          </Alert>
        )}

        <Typography variant="body1">
          <b>Message</b>
        </Typography>

        <TextField
          value={mainConfig?.infoBanner?.message}
          onChange={(e) => {
            mainConfig.infoBanner.message = e.target.value;
            setMainConfig({ ...mainConfig });
          }}
          sx={{ width: '100%' }}
          disabled={mainConfig?.infoBanner?.showAlert ? false : true}
        />
      </Box>
    )
  );
}

function EditPosterTemplates({ open, setOpen, setMainConfig, mainConfig }) {
  const handleClose = async () => {
    setOpen(false);
    try {
      await updateMainConfig(mainConfig);
      alert('Updated Successfully');
    } catch (error) {
      alert('Error Updating');
      console.error(error);
    }
  };

  return (
    <Modal open={open} handleClose={handleClose} cta="Save">
      <Typography variant="h6" fontWeight={'fontWeightBold'}>
        Poster Template Links
      </Typography>
      {mainConfig?.posterTemplatesLinks?.map((link, index) => (
        <div key={index}>
          <Typography variant="body1">
            <b>{link.name}</b>
          </Typography>
          <TextField
            label="Link"
            value={link.link}
            onChange={(e) => {
              link.link = e.target.value;
              mainConfig.posterTemplatesLinks[index] = link;
              setMainConfig({ ...mainConfig });
            }}
            sx={{ margin: 2 }}
          />
          <Divider sx={{ marginY: 1 }} />
        </div>
      ))}
      <Divider sx={{ marginY: 1 }} />
    </Modal>
  );
}

function ViewPosterTemplates({ setMainConfig, mainConfig }) {
  const [open, setOpen] = useState(false);
  return (
    <>
      {mainConfig && (
        <Box padding={2} overflow={'hidden'}>
          <Box display={'flex'} justifyContent={'space-between'}>
            <Typography variant="h6" fontWeight={'fontWeightBold'}>
              Poster Template Links
            </Typography>
            <Button
              variant="text"
              color="primary"
              onClick={() => setOpen(true)}
            >
              Edit
            </Button>
          </Box>
          {mainConfig?.posterTemplatesLinks?.map((link, index) => (
            <div key={index}>
              <Typography variant="body1">
                <b>{link.name}</b>
              </Typography>
              <Link to={`${link.link}`} target="_blank">
                {link.link}
              </Link>
              <Divider sx={{ marginY: 1 }} />
            </div>
          ))}
        </Box>
      )}
      {open && (
        <EditPosterTemplates
          {...{ setMainConfig, mainConfig, open, setOpen }}
        />
      )}
    </>
  );
}

function EditGoogleDriveSubmissionConfig({
  setMainConfig,
  mainConfig,
  open,
  setOpen,
}) {
  const handleClose = async () => {
    setOpen(false);
    try {
      await updateMainConfig(mainConfig);
      alert('Updated Successfully');
    } catch (error) {
      alert('Error Updating');
      console.error(error);
    }
  };

  return (
    <Modal open={open} handleClose={handleClose} cta="Save">
      <Typography variant="h6" fontWeight={'fontWeightBold'}>
        Google Drive Poster Submission Links
      </Typography>
      {mainConfig?.googleDriveFolders?.map((link, index) => (
        <div key={index}>
          <Typography variant="body1">
            <b>{link[(index, 0)]}</b>
          </Typography>
          <TextField
            label="Link"
            value={link[(index, 1)]}
            onChange={(e) => {
              link[(index, 1)] = e.target.value;
              mainConfig.googleDriveFolders[index] = link;
              setMainConfig({ ...mainConfig });
            }}
            sx={{ margin: 2 }}
          />
          <Divider sx={{ marginY: 1 }} />
        </div>
      ))}
      <Divider sx={{ marginY: 1 }} />
    </Modal>
  );
}

function ViewGoogleDriveSubmissionConfig({ setMainConfig, mainConfig }) {
  const [open, setOpen] = useState(false);
  return (
    <>
      {mainConfig && (
        <Box padding={2}>
          <Box display={'flex'} justifyContent={'space-between'}>
            <Typography variant="h6" fontWeight={'fontWeightBold'}>
              Google Drive Poster Submission Links
            </Typography>
            <Button
              variant="text"
              color="primary"
              onClick={() => setOpen(true)}
            >
              Edit
            </Button>
          </Box>
          {mainConfig?.googleDriveFolders?.map((link, index) => (
            <div key={index}>
              <Typography variant="body1">
                <b>{link[(index, 0)]}</b>
              </Typography>
              <Link to={`${link[(index, 1)]}`} target="_blank">
                {link[(index, 1)]}
              </Link>
              <Divider sx={{ marginY: 1 }} />
            </div>
          ))}
        </Box>
      )}
      {open && (
        <EditGoogleDriveSubmissionConfig
          {...{ setMainConfig, mainConfig, open, setOpen }}
        />
      )}
    </>
  );
}

function EditDeadlineConfig({
  open,
  setOpen,
  dates,
  setMainConfig,
  mainConfig,
}) {
  const handleClose = async () => {
    setOpen(false);
    try {
      await updateMainConfig(mainConfig);
      alert('Updated Successfully');
    } catch (error) {
      alert('Error Updating');
      console.error(error);
    }
  };

  return (
    <Modal open={open} handleClose={handleClose} cta="Save">
      <Typography variant="h6" fontWeight={'fontWeightBold'}>
        Deadlines
      </Typography>
      <Typography variant="body1">
        <b>Abstract Submission Deadline</b>
      </Typography>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          value={dayjs(dates?.abstract)}
          onChange={(newValue) => {
            dates.abstract = newValue.toISOString();
            setMainConfig({ ...mainConfig, dueDates: dates });
          }}
        />
      </LocalizationProvider>
      <Divider sx={{ marginY: 1 }} />

      <Typography variant="body1">
        <b>Draft Poster Submission Deadline: Poster Link</b>
      </Typography>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          value={dayjs(dates?.posterLink)}
          onChange={(newValue) => {
            dates.posterLink = newValue.toISOString();
            setMainConfig({ ...mainConfig, dueDates: dates });
          }}
        />
      </LocalizationProvider>
      <Divider sx={{ marginY: 1 }} />

      <Typography variant="body1">
        <b>Draft Poster Submission Deadline: Poster Image</b>
      </Typography>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          value={dayjs(dates?.posterImage)}
          onChange={(newValue) => {
            dates.posterImage = newValue.toISOString();
            setMainConfig({ ...mainConfig, dueDates: dates });
          }}
        />
      </LocalizationProvider>
      <Divider sx={{ marginY: 1 }} />

      <Typography variant="body1">
        <b>Final Poster Submission Deadline: Poster Link</b>
      </Typography>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          value={dayjs(dates?.posterLinkFinal)}
          onChange={(newValue) => {
            dates.posterLinkFinal = newValue.toISOString();
            setMainConfig({ ...mainConfig, dueDates: dates });
          }}
        />
      </LocalizationProvider>
      <Divider sx={{ marginY: 1 }} />

      <Typography variant="body1">
        <b>Final Project Submission Deadline: Poster Image</b>
      </Typography>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          value={dayjs(dates?.posterImageFinal)}
          onChange={(newValue) => {
            dates.posterImageFinal = newValue.toISOString();
            setMainConfig({ ...mainConfig, dueDates: dates });
          }}
        />
      </LocalizationProvider>
      <Divider sx={{ marginY: 1 }} />

      <Typography variant="body1">
        <b>Senior Design Day</b>
      </Typography>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          value={dayjs(dates?.seniorDesignDay)}
          onChange={(newValue) => {
            dates.seniorDesignDay = newValue.toISOString();
            setMainConfig({ ...mainConfig, dueDates: dates });
          }}
        />
      </LocalizationProvider>
      <Divider sx={{ marginY: 1 }} />
    </Modal>
  );
}

function ViewDeadlineConfig({ dates, setMainConfig, mainConfig }) {
  const [open, setOpen] = useState(false);
  return (
    <>
      {dates && (
        <Box padding={2}>
          <Box display={'flex'} justifyContent={'space-between'}>
            <Typography variant="h6" fontWeight={'fontWeightBold'}>
              Deadlines
            </Typography>
            <Button
              variant="text"
              color="primary"
              onClick={() => setOpen(true)}
            >
              Edit
            </Button>
          </Box>
          <Typography variant="body1">
            <b>Abstract Submission Deadline</b>
          </Typography>
          <Typography variant="body2">
            {formatDate(dates?.abstract, true)}
          </Typography>

          <Divider sx={{ marginY: 1 }} />

          <Typography variant="body1">
            <b>Draft Poster Submission Deadline: Poster Link</b>
          </Typography>
          <Typography variant="body2">
            {formatDate(dates?.posterLink, true)}
          </Typography>
          <Divider sx={{ marginY: 1 }} />

          <Typography variant="body1">
            <b>Draft Poster Submission Deadline: Poster Image</b>
          </Typography>
          <Typography variant="body2">
            {formatDate(dates?.posterImage, true)}
          </Typography>
          <Divider sx={{ marginY: 1 }} />

          <Typography variant="body1">
            <b>Final Poster Submission Deadline: Poster Link</b>
          </Typography>
          <Typography variant="body2">
            {formatDate(dates?.posterLinkFinal, true)}
          </Typography>
          <Divider sx={{ marginY: 1 }} />

          <Typography variant="body1">
            <b>Final Project Submission Deadline: Poster Image</b>
          </Typography>
          <Typography variant="body2">
            {formatDate(dates?.posterImageFinal, true)}
          </Typography>
          <Divider sx={{ marginY: 1 }} />

          <Typography variant="body1">
            <b>Senior Design Day</b>
          </Typography>
          <Typography variant="body2">
            {formatDate(dates?.seniorDesignDay, true)}
          </Typography>
          <Divider sx={{ marginY: 1 }} />
        </Box>
      )}
      {open && (
        <EditDeadlineConfig
          open={open}
          setOpen={setOpen}
          dates={dates}
          setMainConfig={setMainConfig}
          mainConfig={mainConfig}
        />
      )}
    </>
  );
}

function AdminConfig() {
  let [mainConfig, setMainConfig] = useState({});
  useEffect(() => {
    async function fetchData() {
      try {
        const mainConfig = await getMainConfig();
        setMainConfig(mainConfig?.data?.data);
      } catch (error) {
        console.error(error);
      }
    }
    fetchData();
  }, []);
  return (
    <>
      <Box {...Styles.mainBox}>
        <Toolbar />

        {/* Container With Max Width = lg breakpoint */}
        <Container {...Styles.customContainer('md')}>
          {/*Outer Grid Container */}
          <Grid container spacing={3}>
            {/*Inner Grid Begins*/}

            {/*Inner Grid Item 1: Page Title */}
            <Grid item lg={12} sm={12} xs={12}>
              <Paper {...Styles.dashboardPageWidget()}>
                Manage Configuration
              </Paper>
            </Grid>

            {/*Inner Grid Item 2:  View Deadline Config */}
            <Grid item lg={12} sm={12} xs={12}>
              <Paper {...Styles.dashboardContentWidget('auto')}>
                <ViewDeadlineConfig
                  dates={mainConfig?.dueDates}
                  mainConfig={mainConfig}
                  setMainConfig={setMainConfig}
                />
              </Paper>
            </Grid>

            {/*Inner Grid Item 2:  View Google Drive Links Config */}
            <Grid item lg={12} sm={12} xs={12}>
              <Paper {...Styles.dashboardContentWidget('auto')}>
                <ViewGoogleDriveSubmissionConfig
                  mainConfig={mainConfig}
                  setMainConfig={setMainConfig}
                />
              </Paper>
            </Grid>

            {/*Inner Grid Item 3:  View Poster Template Links */}
            <Grid item lg={12} sm={12} xs={12}>
              <Paper {...Styles.dashboardContentWidget('auto')}>
                <ViewPosterTemplates
                  mainConfig={mainConfig}
                  setMainConfig={setMainConfig}
                />
              </Paper>
            </Grid>

            {/*Inner Grid Item 4:  View and Edit Info Banner*/}
            <Grid item lg={12} sm={12} xs={12}>
              <Paper {...Styles.dashboardContentWidget('auto')}>
                <ViewAndEditInfoBanner
                  mainConfig={mainConfig}
                  setMainConfig={setMainConfig}
                />
              </Paper>
            </Grid>

            {/*Inner Grid Item 5:  View and Edit Msic*/}
            <Grid item lg={12} sm={12} xs={12}>
              <Paper {...Styles.dashboardContentWidget('auto')}>
                <ViewAndEditMisc
                  mainConfig={mainConfig}
                  setMainConfig={setMainConfig}
                />
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
}

export default function AdminDashboardConfig() {
  return <Dashboard Page={AdminConfig} />;
}

import { Alert, Box, Button, Chip, Divider, Typography } from '@mui/material';
import { StylesForm } from '../../styles/global';
import { useState } from 'react';
import ViewRubric from './ViewRubric';
import AdvancedModal from '../AdvancedModal';
import { deleteAssignment } from '../../utils/API';

// Widget for Viewing / Deleting Rubrics
const DeleteableRubric = ({ assignment, flag, setFlag }) => {
  const [showModal, setShowModal] = useState(false);
  const handleDelete = async () => {
    try {
      await deleteAssignment(assignment._id);
      alert('Assignment Deleted Successfully');
      setFlag(!flag);
    } catch (error) {
      console.error('Error Deleting Assignment:', error);
      alert('Error Deleting Assignment');
    }
  };

  return (
    assignment && (
      <>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          {/* Add Assignments */}
          <Box display={'flex'} alignItems={'center'} gap={1}>
            <Typography {...StylesForm.text('h6')}>
              {assignment?.rubricCopy?.title}
            </Typography>
            <Chip label={assignment?.rubricCopy?.type} />
            <Chip label={assignment?.rubricCopy?.course} />
            <Chip label={assignment?.rubricCopy?.department} />|
            <Chip label={assignment?.judgeDetails?.fullName} />
            <Chip
              label={assignment?.status}
              color={assignment?.status === 'PENDING' ? 'default' : 'success'}
            />
          </Box>
          <Button color="error" onClick={() => setShowModal(true)}>
            Delete
          </Button>
        </Box>

        <Divider sx={{ margin: 2 }} />

        <ViewRubric
          rubric={assignment?.rubricCopy}
          scores={assignment?.scores}
        />
        <AdvancedModal
          open={showModal}
          handleClose={() => setShowModal(false)}
          title="Delete Judge Assignment"
          cta="Delete"
          handleSave={handleDelete}
        >
          <Alert severity="error">
            Are you sure you want to delete this assignment? This action cannot
            be undone. It will also delete all the scores associated with this
            assignment.
          </Alert>
        </AdvancedModal>
      </>
    )
  );
};

export default DeleteableRubric;

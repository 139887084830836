import { Box, Container, Grid, Paper, Toolbar } from '@mui/material';
import Dashboard from '../../../../components/Judge/Dashboard';
import { Styles } from '../../../../styles/global';
import { useEffect, useRef, useState } from 'react';
import { getProjects, objectToQueryString } from '../../../../utils/API';
import { useSearchParams } from 'react-router-dom';
import ProjectsView from '../../../../components/Views';
import { useAuth } from '../../../../utils/AuthContext';

function Projects() {
  const [projects, setProjects] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const topRef = useRef(null); // Create a ref for the top of the page
  const { user } = useAuth();

  useEffect(() => {
    if (!searchParams.get('limit')) {
      setSearchParams(`${searchParams.toString()}&limit=20`);
    }
    if (!searchParams.get('page')) {
      setSearchParams(`${searchParams.toString()}&page=1`);
    }
  }, [searchParams, setSearchParams]);

  useEffect(() => {
    async function fetchProjects(params) {
      try {
        const projectsResponse = await getProjects(params);
        setProjects(projectsResponse?.data || []);
      } catch (error) {
        console.log(error);
      }
    }

    const params = {
      department: user.department,
      year: new Date().getFullYear(),
      course: user.course,
      limit: searchParams.get('limit') || 20,
      page: searchParams.get('page') || 1,
    };

    fetchProjects(objectToQueryString(params));
  }, [searchParams, user]);

  return (
    <>
      <Box {...Styles.mainBox}>
        <Toolbar />

        {/* Container With Max Width = lg breakpoint */}
        <Container {...Styles.customContainer('xl')}>
          {/*Outer Grid Container */}
          <Grid container spacing={3}>
            {/*Inner Grid Begins*/}

            {/*Inner Grid Item 0: Space Taken */}
            <Grid item lg={2} sm={0} xs={0} ref={topRef}>
              <div />
            </Grid>

            {/*Inner Grid Item 1: Page Title */}
            <Grid item lg={10} sm={12} xs={12} ref={topRef}>
              <Paper {...Styles.dashboardPageWidget()}>Projects Bids</Paper>
            </Grid>

            {/*Inner Grid Item 2: Filters */}
            <Grid item lg={2} sm={12} xs={12}>
              <></>
            </Grid>

            {/*Inner Grid Item 3: Projects View */}
            <Grid item lg={10} sm={12} xs={12}>
              <Paper {...Styles.dashboardContentMinWidget()}>
                <ProjectsView
                  items={projects}
                  topRef={topRef}
                  link="/judge/dashboard/projects"
                  itemType={'projects'}
                />
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
}

export default function JudgeDashboardProjects() {
  return <Dashboard Page={Projects} />;
}

import { ExpandMore } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Container,
  Divider,
  Typography,
} from '@mui/material';
import 'react-quill/dist/quill.snow.css';
export default function Dynamic({ content, title }) {
  return (
    <>
      <Container maxWidth="lg" sx={{ marginY: 1 }}>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <Typography variant="h6" color="#0F284B ">
              {title}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Divider />
            <div
              className="ql-editor"
              dangerouslySetInnerHTML={{
                __html: content,
              }}
              style={{
                whiteSpace: 'pre-wrap',
              }}
            />
          </AccordionDetails>
        </Accordion>
      </Container>
    </>
  );
}

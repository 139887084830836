import { Box, Container, Grid, Paper, Toolbar } from '@mui/material';
import Dashboard from '../../../../components/Faculty/Dashboard';
import { Styles } from '../../../../styles/global';
import { useEffect, useRef, useState } from 'react';
import { getAvailableYears, getProjects } from '../../../../utils/API';
import { useSearchParams } from 'react-router-dom';
import Filters from '../../../../components/Filters';
import projectsFiltersSchema from '../../../../utils/FiltersSchema/FacultyProjects';
import ProjectsView from '../../../../components/Views';

function Projects() {
  const [projects, setProjects] = useState([]);
  const [years, setYears] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const topRef = useRef(null); // Create a ref for the top of the page

  useEffect(() => {
    if (!searchParams.get('limit')) {
      setSearchParams(`${searchParams.toString()}&limit=10`);
    }
    if (!searchParams.get('page')) {
      setSearchParams(`${searchParams.toString()}&page=1`);
    }
  }, [searchParams, setSearchParams]);

  useEffect(() => {
    async function fetchAvailableYears() {
      try {
        const yearsResponse = await getAvailableYears(
          searchParams.get('department' || '')
        );
        const availableYears =
          yearsResponse?.data?.data?.sort(function (a, b) {
            return b - a;
          }) || [];
        setYears(availableYears.map((year) => year.toString()));
      } catch (error) {
        console.log(error);
      }
    }
    async function fetchProjects() {
      try {
        const projectsResponse = await getProjects(searchParams.toString());
        setProjects(projectsResponse?.data || []);
      } catch (error) {
        console.log(error);
      }
    }
    try {
      fetchAvailableYears();
      fetchProjects();
    } catch (error) {
      console.log(error);
    }
  }, [searchParams]);

  return (
    <>
      <Box {...Styles.mainBox}>
        <Toolbar />

        {/* Container With Max Width = lg breakpoint */}
        <Container {...Styles.customContainer('xl')}>
          {/*Outer Grid Container */}
          <Grid container spacing={3}>
            {/*Inner Grid Begins*/}

            {/*Inner Grid Item 0: Space Taken */}
            <Grid item lg={3} sm={0} xs={0} ref={topRef}>
              <div />
            </Grid>

            {/*Inner Grid Item 1: Page Title */}
            <Grid item lg={9} sm={12} xs={12} ref={topRef}>
              <Paper {...Styles.dashboardPageWidget()}>Projects</Paper>
            </Grid>

            {/*Inner Grid Item 2: Filters */}
            <Grid item lg={3} sm={12} xs={12}>
              <Box {...Styles.dashboardContentWidget('auto')}>
                <Filters
                  filtersSchema={projectsFiltersSchema({
                    avaliableYears: years,
                  })}
                />
              </Box>
            </Grid>

            {/*Inner Grid Item 3: Projects View */}
            <Grid item lg={9} sm={12} xs={12}>
              <Paper {...Styles.dashboardContentMinWidget()}>
                <ProjectsView
                  items={projects}
                  topRef={topRef}
                  link="/faculty/dashboard/projects"
                  itemType={'projects'}
                />
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
}

export default function FacultyDashboardProjects() {
  return <Dashboard Page={Projects} />;
}

import { Box } from '@mui/material';
import Chatbot from '../ChatBot';
import Navbar from '../AttendeeNavBar';
import {
  AccordionImage,
  Footer,
  TextBanner,
  TwoButtonLink,
  YouTube,
  Text,
  AccordionDynamic,
} from './UI';
import Dynamic from './UI/DynamicItem';
import LoadingPage from '../../pages/Loading';
import Image from './UI/Image';

function DynamicItem({ item }) {
  function renderItem() {
    switch (item?.component) {
      case 'TextBanner':
        return <TextBanner {...item} />;
      case 'TwoButtonLink':
        return <TwoButtonLink {...item} />;
      case 'YouTube':
        return <YouTube {...item} />;
      case 'Footer':
        return <Footer />;
      case 'AccordionImage':
        return <AccordionImage {...item} />;
      case 'Text':
        return <Text {...item} />;
      case 'AccordionDynamic':
        return <AccordionDynamic {...item} />;
      case 'Dynamic':
        return <Dynamic {...item} />;
      case 'Image':
        return <Image {...item} />;
      default:
        return <></>;
    }
  }
  return <Box sx={{ width: '100%' }}>{renderItem()}</Box>;
}

export default function DynamicContent({
  content,
  setContent,
  loading,
  infoBanner = null,
  page = null,
}) {
  return loading ? (
    <LoadingPage />
  ) : (
    <Box>
      <Navbar
        infoBanner={infoBanner}
        edit={true}
        page={page}
        content={content}
        setContent={setContent}
      />
      {content &&
        content?.map((item, index) => <DynamicItem key={index} item={item} />)}
      <Chatbot />
    </Box>
  );
}

import { Box } from '@mui/material';
import { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
export default function DynamicItem({ component, handleChange }) {
  const [localContent, setLocalContent] = useState(component?.content || '');
  return (
    <Box display="flex" flexDirection={'column'} gap={2}>
      <ReactQuill
        theme="snow"
        value={localContent}
        onChange={setLocalContent}
        onBlur={() => handleChange({ ...component, content: localContent })}
      />
    </Box>
  );
}

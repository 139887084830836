import { Typography, Box, Chip, Divider, Avatar } from '@mui/material';
import { GroupWorkOutlined } from '@mui/icons-material';

export default function BasicProject({ project }) {
  if (!project) {
    return <></>;
  }
  return (
    <Box display="block">
      <Divider />
      <Box
        display="flex"
        flexDirection={'column'}
        margin={2}
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <GroupWorkOutlined />
        <Box display={'block'} marginY={1} gap={1}>
          <Typography
            variant="body1"
            fontFamily="fantasy"
            fontWeight={'fontWeightBold'}
            textOverflow={'ellipsis'}
          >
            {project?.title}
          </Typography>
        </Box>
        <Box display="flex" flexDirection="row" flexWrap={true} marginY={1}>
          <Typography
            variant="subtitle2"
            fontFamily={'fantasy'}
            whiteSpace={'preserve'}
          >
            {'  '}|
          </Typography>
          {project?.ownersDescription.map((owner, index) => (
            <div key={index}>
              <Typography
                variant="subtitle2"
                fontFamily={'fantasy'}
                whiteSpace={'preserve'}
              >
                {`  ${owner?.fullName}  |`}
              </Typography>
            </div>
          ))}
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          flexWrap={true}
          gap={1}
          marginY={1}
        >
          <Chip label={project?.course} color="success" variant="outlined" />
          <Chip label={project?.department} color="info" variant="outlined" />
          <Chip label={project?.year} color="primary" variant="outlined" />
        </Box>
      </Box>
      <Divider />
    </Box>
  );
}

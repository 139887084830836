import { Box, Divider, Typography } from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import { StylesForm } from '../../../styles/global';

// Widget for Poster Link
const InformPosterLink = ({ project, dueDate, showLink = false }) => {
  const { pid } = useParams();
  return (
    <>
      {project && (
        <>
          {/* Project Title + Due Date */}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            {/* Project Title */}
            <Typography {...StylesForm.text('h6')}>
              {project.data.title.length > 10
                ? `${project.data.title.substring(0, 10)}... Poster Link`
                : `${project.data.title} Poster Link`}
            </Typography>
            <Divider />
            {/* Due Date */}
            <Typography
              {...StylesForm.text('p')}
              sx={{
                marginBottom: 1,
                color: '#226E93',
              }}
            >
              (due {dueDate || 'unassigned'})
            </Typography>
          </Box>

          <Divider />

          {/* Poster Link */}
          <Link
            {...StylesForm.text('p')}
            to={!project.data.posterLink ? '#' : project.data.posterLink}
            target="_blank"
            style={{
              marginTop: 10,
              marginBottom: 10,
              marginLeft: 4,
              color: '#226E93',
              overflowWrap: 'break-word',
              wordBreak: 'break-all',
            }}
          >
            {!project.data.posterLink
              ? 'No poster link submitted.'
              : project.data.posterLink}
          </Link>

          {/* Verify Link Works Statement */}
          <Typography
            {...StylesForm.text('p')}
            sx={{
              marginBottom: 1,
            }}
          >
            Please verify the link works and links to your poster after
            submission.
          </Typography>

          {/* Link to add poster link */}
          {showLink && (
            <Box display={'flex'} alignItems={'end'} height={'100%'}>
              <Link
                to={`/student/dashboard/project/${pid}/poster`}
                style={{ color: '#1976D2', margin: 3 }}
              >
                {' '}
                To submit the poster link and image, click here{' '}
              </Link>
            </Box>
          )}
        </>
      )}
    </>
  );
};

export default InformPosterLink;

import { Box, Input } from '@mui/material';
import { useState } from 'react';
import { uploadImage } from '../../../../utils/API';

export default function AccordionImage({ component, handleChange }) {
  const [localContent, setLocalContent] = useState(component?.imageSrc || '');
  const [localContent2, setLocalContent2] = useState(component?.title || '');
  async function handleImageChange(e) {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append('file', file);
    try {
      const res = await uploadImage(formData);
      const newComponent = {
        ...component,
        imageSrc: `${process.env.REACT_APP_SPACES_ENDPOINT_STATIC}${res.data.data}`,
      };
      handleChange(newComponent);
    } catch (err) {
      console.log(err);
      alert('Failed to upload image');
    }
  }
  function handleContentChange() {
    const newComponent = { ...component, imageAlt: localContent };
    handleChange(newComponent);
  }
  function handleContentChange2() {
    const newComponent = { ...component, title: localContent2 };
    handleChange(newComponent);
  }

  return (
    <Box display="flex" flexDirection={'column'} gap={2}>
      <h5>Image Src</h5>
      <Box display="flex" justifyContent={'space-between'}>
        <Input
          value={component?.imageSrc || ''}
          disabled={true}
          placeholder="No File Chosen"
          accept="image/*"
          fullWidth
        />
        <Input type="file" onChange={(e) => handleImageChange(e)} />
      </Box>

      <h5>Title</h5>
      <Input
        value={localContent2}
        fullWidth
        onChange={(e) => setLocalContent2(e.target.value)}
        onBlur={() => handleContentChange2()}
      />

      <h5>Image Alt Text</h5>
      <Input
        value={localContent}
        fullWidth
        onChange={(e) => setLocalContent(e.target.value)}
        onBlur={() => handleContentChange()}
      />
    </Box>
  );
}

import { Container, Typography } from '@mui/material';

export default function Text({
  text,
  variant,
  bold = false,
  highlight = false,
}) {
  return (
    <Container maxWidth="lg">
      {/* allow for spaces */}
      <Typography
        variant={variant}
        sx={{
          fontWeight: bold ? 'bold' : 'normal',
          whiteSpace: 'pre-wrap',
          bgcolor: highlight ? '#ffdb53' : 'transparent',
        }}
      >
        {text}
      </Typography>
    </Container>
  );
}

import { Check, QuestionAnswer } from '@mui/icons-material';
import { Box, Chip, Divider, Grid, Typography } from '@mui/material';
import { useAuth } from '../../utils/AuthContext';

export default function SearchProject({ project }) {
  const { user } = useAuth();
  if (!project) {
    return <></>;
  }
  return (
    <Box padding={2}>
      <Grid container spacing={3}>
        <Grid item lg={5} md={5} sm={0} xs={0}>
          <Box sx={{ display: { lg: 'block', md: 'block', xs: 'none' } }}>
            <img
              src={`${process.env.REACT_APP_SPACES_ENDPOINT_POSTER_IMAGES}${project?.posterImage}`}
              alt={project?.title}
              width={'100%'}
            />
          </Box>
        </Grid>
        <Grid item lg={7} md={7} sm={12} xs={12}>
          <Box height="auto" marginLeft={2}>
            <Box display="flex" flexDirection={'column'} margin={2} sx={{}}>
              <Box display={'block'} marginY={1} gap={1}>
                <Typography
                  variant="body1"
                  fontFamily="Tiempos Headline"
                  fontWeight={'fontWeightBold'}
                  textOverflow={'ellipsis'}
                >
                  {project?.title.slice(0, 100)}
                  {project?.title.length > 100 && '...'}
                </Typography>
              </Box>
              <Box
                display="flex"
                flexDirection="row"
                flexWrap={true}
                marginY={1}
              >
                <Typography
                  variant="subtitle2"
                  fontFamily="Tiempos Headline"
                  whiteSpace={'preserve'}
                >
                  {'  '}|
                </Typography>
                {project?.ownersDescription.map((owner, index) => (
                  <div key={index}>
                    <Typography
                      variant="subtitle2"
                      fontFamily="Tiempos Headline"
                      whiteSpace={'preserve'}
                    >
                      {`  ${owner?.fullName}  |`}
                    </Typography>
                  </div>
                ))}
              </Box>
              <Box
                display="flex"
                flexDirection="row"
                flexWrap={true}
                gap={1}
                marginY={1}
              >
                <Chip
                  label={project?.course}
                  color="success"
                  variant="outlined"
                />
                <Chip
                  label={project?.department}
                  color="info"
                  variant="outlined"
                />
                <Chip
                  label={project?.year}
                  color="primary"
                  variant="outlined"
                />
                |
                <Chip
                  label={'Abstract'}
                  color={project?.abstract ? 'success' : 'error'}
                  variant="outlined"
                />
                <Chip
                  label={'Poster Link'}
                  color={project?.posterLink ? 'success' : 'error'}
                  variant="outlined"
                />
                {project?.projectBids?.includes(user?._id) && (
                  <Chip
                    label={'Bid'}
                    icon={<Check />}
                    color={'success'}
                    variant="outlined"
                  />
                )}
              </Box>
              <Box display="flex" marginY={1}>
                <QuestionAnswer />
                <Typography
                  variant="subtitle2"
                  fontFamily={'fantasy'}
                  marginLeft={2}
                >
                  {project?.abstract?.slice(0, 250) || 'No Abstract'}
                  {project?.abstract?.length > 250 && '...'}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Divider sx={{ margin: 1 }} />
    </Box>
  );
}

import { Box, Paper } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import NavBar from '../../components/AttendeeNavBar.js';
import { useAuth } from '../../utils/AuthContext.js';
import Card from '../../components/Card.js';

import { Footer, TextBanner } from '../../components/Atendee/UI';

const Preset = {
  title: 'Student Portal',
  description: 'Navigate Your Senior Design Journey with Ease',
  portal: 'Portal',
  image: `${process.env.REACT_APP_SPACES_ENDPOINT_STATIC}studentPortal.PNG`,
  cardTitle: 'Student Portal',
  cardBody:
    'The UM-Dearborn Student SDC Portal is the centralized online platform for submitting senior design projects.',
  date: 'Welcome Back!',
  feature: 'Student Features',
};

const Portal = ({ content }) => {
  const { user, isLoggedIn } = useAuth();
  const navigate = useNavigate();

  // Handles the navigation to portal
  const handleNavigation = () => {
    if (isLoggedIn && user.role === 'STUDENT') {
      if (user.projectsOwned.length === 0) {
        navigate('/student/dashboard/project/0');
        return;
      }
      navigate(`/student/dashboard/project/${user.projectsOwned[0]._id}`);
      return;
    }
    navigate('/student/login');
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        gap: 4,
        alignItems: 'center',
        py: 3,
        m: 2,
      }}
    >
      <Card
        image={content.image}
        cardTitle={content.cardTitle}
        cardBody={content.cardBody}
        onClick={handleNavigation}
      />
      <Paper
        elevation={3}
        sx={{
          fontSize: '20px',
          p: 3,
        }}
      >
        {content.date}
      </Paper>
    </Box>
  );
};

const Student = () => {
  const [content] = useState(Preset);

  return (
    <>
      <NavBar />
      <TextBanner
        text="Student Portal"
        body="The UM-Dearborn Student SDC Portal is the centralized online platform for submitting senior design projects."
        bgcolor="#00274C"
        textColor="white"
      />
      <Portal content={content} />
      <TextBanner
        text="Student Features"
        body="The UM-Dearborn Student SDC Portal is the centralized online platform for submitting senior design projects. Create Projects, Submit Abstracts, Submit Posters, and Organize Teams."
        bgcolor="#ffdb53"
        textColor="#00274c"
      />
      <Footer />
    </>
  );
};

export default Student;

import { Box, Input } from '@mui/material';
import { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
export default function AccordionDynamic({ component, handleChange }) {
  const [localContent, setLocalContent] = useState(component?.content || '');
  const [localTitle, setLocalTitle] = useState(component?.title || '');
  function handleContentChange() {
    const newComponent = { ...component, content: localContent };
    handleChange(newComponent);
  }
  function handleTitleChange() {
    const newComponent = { ...component, title: localTitle };
    handleChange(newComponent);
  }

  return (
    <Box display="flex" flexDirection={'column'} gap={2}>
      <h5>Title</h5>
      <Input
        value={localTitle}
        fullWidth
        onChange={(e) => setLocalTitle(e.target.value)}
        onBlur={() => handleTitleChange()}
      />

      <h5>Content</h5>
      <ReactQuill
        theme="snow"
        value={localContent}
        onChange={setLocalContent}
        onBlur={handleContentChange}
      />
    </Box>
  );
}

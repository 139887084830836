import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ArticleIcon from '@mui/icons-material/Article';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import HomeIcon from '@mui/icons-material/Home';
import {
  FileDownload,
  Person,
  Scoreboard,
  ToggleOn,
} from '@mui/icons-material';
import EventIcon from '@mui/icons-material/Event';
import ContactPageIcon from '@mui/icons-material/ContactPage';
import { Link } from 'react-router-dom';

export const mainListItems = (searchParams) => (
  <React.Fragment>
    <Link
      style={{ textDecoration: 'none', color: 'inherit' }}
      to={`/admin/dashboard?${searchParams}`}
    >
      <ListItemButton>
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary="Dashboard" />
      </ListItemButton>
    </Link>
    <Link
      style={{ textDecoration: 'none', color: 'inherit' }}
      to={`/admin/dashboard/users?${searchParams}`}
    >
      <ListItemButton>
        <ListItemIcon>
          <Person />
        </ListItemIcon>
        <ListItemText primary="Users" />
      </ListItemButton>
    </Link>
    <Link
      style={{ textDecoration: 'none', color: 'inherit' }}
      to={`/admin/dashboard/projects?${searchParams}`}
    >
      <ListItemButton>
        <ListItemIcon>
          <ArticleIcon />
        </ListItemIcon>
        <ListItemText primary="Projects" />
      </ListItemButton>
    </Link>
    <Link
      style={{ textDecoration: 'none', color: 'inherit' }}
      to={`/admin/dashboard/tutorials?${searchParams}`}
    >
      <ListItemButton>
        <ListItemIcon>
          <AddPhotoAlternateIcon />
        </ListItemIcon>
        <ListItemText primary="Tutorials" />
      </ListItemButton>
    </Link>
    <Link
      style={{ textDecoration: 'none', color: 'inherit' }}
      to={`/admin/dashboard/rubrics?${searchParams}`}
    >
      <ListItemButton>
        <ListItemIcon>
          <Scoreboard />
        </ListItemIcon>
        <ListItemText primary="Rubrics" />
      </ListItemButton>
    </Link>
    <Link
      style={{ textDecoration: 'none', color: 'inherit' }}
      to={`/admin/dashboard/reports?${searchParams}`}
    >
      <ListItemButton>
        <ListItemIcon>
          <FileDownload />
        </ListItemIcon>
        <ListItemText primary="Reports" />
      </ListItemButton>
    </Link>
    <Link
      style={{ textDecoration: 'none', color: 'inherit' }}
      to={`/admin/dashboard/config?${searchParams}`}
    >
      <ListItemButton>
        <ListItemIcon>
          <ToggleOn />
        </ListItemIcon>
        <ListItemText primary="Configuration" />
      </ListItemButton>
    </Link>
  </React.Fragment>
);

export const secondaryListItems = (pid) => (
  <React.Fragment>
    <ListSubheader component="div" inset>
      Atendee Pages
    </ListSubheader>
    <Link style={{ textDecoration: 'none', color: 'inherit' }} to="/">
      <ListItemButton>
        <ListItemIcon>
          <HomeIcon />
        </ListItemIcon>
        <ListItemText primary="Home" />
      </ListItemButton>
    </Link>
    <Link
      style={{ textDecoration: 'none', color: 'inherit' }}
      to="/participant-info"
    >
      <ListItemButton>
        <ListItemIcon>
          <EventIcon />
        </ListItemIcon>
        <ListItemText primary="Participant" />
      </ListItemButton>
    </Link>
    <Link style={{ textDecoration: 'none', color: 'inherit' }} to="/contact">
      <ListItemButton>
        <ListItemIcon>
          <ContactPageIcon />
        </ListItemIcon>
        <ListItemText primary="Contact" />
      </ListItemButton>
    </Link>
  </React.Fragment>
);

import { Box, Button, Paper } from '@mui/material';
import { useEffect, useState } from 'react';
import Form from './Form';
import Table from './Table';
import { createReport, getReports } from '../../utils/API';

export default function Report({ title, reportType, fieldsList = [] }) {
  const [fields, setFields] = useState(
    fieldsList.reduce((acc, field) => ({ ...acc, [field]: '' }), {})
  );
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [flag, setFlag] = useState(false);

  const handleSubmit = async () => {
    try {
      const response = await createReport(reportType?.toLowerCase(), {
        ...fields,
        year: parseInt(fields?.year),
      });
      alert(response?.data?.message);
      setFlag(!flag);
    } catch (err) {
      console.log(err);
      alert(err?.response?.data?.errMessage || err?.response?.data?.message);
    }
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await getReports(reportType);
        const temp = response?.data?.columns.map((row) => {
          if (row?.field === 'file') {
            return {
              ...row,
              renderCell: (params) => (
                <Button
                  variant="contained"
                  href={params?.row?.file}
                  target="_blank"
                  rel="noreferrer"
                >
                  Download
                </Button>
              ),
            };
          }
          return row;
        });
        setColumns(temp);
        setRows(response?.data?.rows);
      } catch (err) {
        console.log(err);
      }
    }
    fetchData();
  }, [reportType, flag]);

  return (
    <Paper sx={{ border: 1.5 }}>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{ fontSize: 26, fontWeight: '500', borderBottom: 1.5 }}
        padding={1}
      >
        {title}
      </Box>
      <Form
        fieldsList={fieldsList}
        fields={fields}
        setFields={setFields}
        handleSubmit={handleSubmit}
      />
      {rows && columns && <Table rows={rows} columns={columns} />}
    </Paper>
  );
}

import { Box, Button } from '@mui/material';
import DynamicItem from './DynamicItem';
import AccordionImage from './AccordionImage';
import Image from './Image';
import Youtube from './Youtube';
import AccordionDynamic from './AccordionDynamic';
import { TextBanner } from './TextBanner';
import TwoButtonLink from './TwoButtonLink';
function FormBox({ index, handleChange, uiComponent }) {
  const handleInnerChange = (newComponent) => {
    handleChange(index, 'update', newComponent);
  };
  const BoxWrapper = ({ children }) => (
    <Box width="100%" marginTop={1}>
      <Box
        display="flex"
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <h5>{uiComponent?.component || ''}</h5>
        <Button
          variant="text"
          color="error"
          onClick={() => handleChange(index, 'delete', null)}
        >
          Delete
        </Button>
      </Box>
      <Box padding={1} border={1}>
        <Box xs={12}>{children}</Box>
      </Box>
    </Box>
  );
  const DynamicComponent = (component) => {
    switch (component) {
      case 'Dynamic':
        return (
          <BoxWrapper>
            <DynamicItem
              component={uiComponent}
              handleChange={handleInnerChange}
            />
          </BoxWrapper>
        );
      case 'AccordionImage':
        return (
          <BoxWrapper>
            <AccordionImage
              component={uiComponent}
              handleChange={handleInnerChange}
            />
          </BoxWrapper>
        );

      case 'Image':
        return (
          <BoxWrapper>
            <Image component={uiComponent} handleChange={handleInnerChange} />
          </BoxWrapper>
        );

      case 'YouTube':
        return (
          <BoxWrapper>
            <Youtube component={uiComponent} handleChange={handleInnerChange} />
          </BoxWrapper>
        );

      case 'AccordionDynamic':
        return (
          <BoxWrapper>
            <AccordionDynamic
              component={uiComponent}
              handleChange={handleInnerChange}
            />
          </BoxWrapper>
        );

      case 'TextBanner':
        return (
          <BoxWrapper>
            <TextBanner
              component={uiComponent}
              handleChange={handleInnerChange}
            />
          </BoxWrapper>
        );

      case 'TwoButtonLink':
        return (
          <BoxWrapper>
            <TwoButtonLink
              component={uiComponent}
              handleChange={handleInnerChange}
            />
          </BoxWrapper>
        );

      case 'Footer':
        return (
          <BoxWrapper>
            <>No Inputs</>
          </BoxWrapper>
        );
      default:
        return <></>;
    }
  };
  return <>{DynamicComponent(uiComponent?.component)}</>;
}
export default function FormComponent({ index, handleChange, uiComponent }) {
  return (
    <Box>
      <FormBox
        index={index}
        handleChange={handleChange}
        uiComponent={uiComponent}
      />
    </Box>
  );
}

export const Styles = {
  //Usage {...Styles.key()}

  // Defines a Max Width Container. Input to Box Component
  customContainer: function (maxWidth = 'lg', mt = 5, mb = 4) {
    return {
      maxWidth,
      sx: {
        mt,
        mb,
      },
    };
  },

  // Returns Props for the main box component.
  mainBox: {
    component: 'main',
    sx: {
      backgroundColor: (theme) =>
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[900],
      flexGrow: 1,
      height: '100vh',
      overflow: 'auto',
    },
  },

  // Returns Props for A Dashboard Page Widget. Input to Paper Component.
  dashboardPageWidget: function (
    fontSize = '1.7rem',
    color = '#1976D2',
    height = { lg: 80, md: 'auto' }
  ) {
    return {
      elevation: 3,
      square: false,
      sx: {
        p: 2,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row',
        fontSize,
        height,
        color,
        overflow: 'auto',
      },
    };
  },

  // Returns Props for A Dashboard Content Widget. Input to Paper Component.
  dashboardContentWidget: function (height = { lg: 340, md: 'auto' }) {
    return {
      elevation: 3,
      square: false,
      sx: {
        p: 2,
        display: 'flex',
        flexDirection: 'column',
        height,
        overflow: 'auto',
      },
    };
  },

  // Returns Props for A Dashboard Content Widget. Input to Paper Component.
  dashboardContentMinWidget: function (minHeight = 800) {
    return {
      elevation: 3,
      square: false,
      sx: {
        p: 2,
        display: 'flex',
        flexDirection: 'column',
        height: 'auto',
        overflow: 'auto',
        minHeight,
        alignItems: 'center',
        justifyContent: 'space-between',
      },
    };
  },
};

export const StylesForm = {
  // Paragraph With Some Margin
  text: function (variant) {
    return { variant, margin: 1 };
  },

  // Defaul Input Prop to Text Field Component
  input: function (name, label, required = true, fullWidth = true) {
    return {
      name,
      id: name,
      label,
      required,
      fullWidth,
      sx: { marginTop: 2 },
    };
  },
};

import { Box, Button, Chip, Divider, Typography } from '@mui/material';
import { StylesForm } from '../../styles/global';
import { useState } from 'react';
import ViewRubric from './ViewRubric';
import { submitScores } from '../../utils/API';

// Scoring Rubric Component
const ScorableRubric = ({ assignment, flag, setFlag }) => {
  const [scores, setScores] = useState(
    assignment?.scores?.length
      ? assignment?.scores.map((score) => String(score))
      : Array(assignment?.rubricCopy?.numOfRubricCategories).fill('')
  );

  const handleScoreChange = (index, value) => {
    if (
      value !== '' &&
      (value < 1 || value > assignment?.rubricCopy?.numOfScoreCategories)
    ) {
      return;
    }
    const newScores = [...scores];
    newScores[index] = value;
    setScores(newScores);
  };

  const onSubmit = async () => {
    const scoresNumbers = scores.map((score) =>
      score === '' ? 0 : Number(score)
    );
    console.log(scoresNumbers);
    try {
      await submitScores(assignment._id, { scores: scoresNumbers });
      alert('Score Submitted Successfully');
      setFlag(!flag);
    } catch (error) {
      console.error('Error Submitting Score:', error);
      alert('Error Submitting Score');
    }
  };

  const disabled = scores.some((score) => score === '');

  return (
    assignment && (
      <>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          {/* Add Assignments */}
          <Box display={'flex'} alignItems={'center'} gap={1}>
            <Typography {...StylesForm.text('h6')}>
              {assignment?.rubricCopy?.title}
            </Typography>
            <Chip label={assignment?.rubricCopy?.type} />
            <Chip label={assignment?.rubricCopy?.course} />
            <Chip label={assignment?.rubricCopy?.department} />|
            <Chip label={assignment?.judgeDetails?.fullName} />
            <Chip
              label={assignment?.status}
              color={assignment?.status === 'PENDING' ? 'default' : 'success'}
            />
          </Box>
          {assignment?.status === 'PENDING' && (
            <Button color="success" disabled={disabled} onClick={onSubmit}>
              Submit Score
            </Button>
          )}
          {assignment?.status === 'COMPLETED' && (
            <Button color="info" disabled={disabled} onClick={onSubmit}>
              Resubmit Score
            </Button>
          )}
        </Box>

        <Divider sx={{ margin: 2 }} />

        <ViewRubric
          rubric={assignment?.rubricCopy}
          scores={scores}
          handleScoreChange={handleScoreChange}
        />
      </>
    )
  );
};

export default ScorableRubric;

const PROJECT_ADMIN_SECTIONS = {
  department: 'department',
  course: 'course',
  year: 'year',
  limit: 'limit',
};

const RUBRIC_ADMIN_SECTION = {
  type: 'type',
  department: 'department',
  course: 'course',
  limit: 'limit',
};

const PROJECT_ADMIN_FIELDS = {
  department: {
    name: 'department',
    options: [
      { value: 'CIS', label: 'CIS' },
      { value: 'ECE', label: 'ECE' },
      { value: 'ME', label: 'ME' },
      { value: 'IMSE', label: 'IMSE' },
    ],
  },
  course: {
    name: 'course',
    options: [
      { value: 'SD1', label: 'Senior Design 1' },
      { value: 'SD2', label: 'Senior Design 2' },
    ],
  },
  year: {
    name: 'year',
    options: (avaliableYears) => {
      return avaliableYears.map((year) => ({ value: year, label: year }));
    },
  },
  limit: {
    name: 'limit',
    options: [
      { value: '10', label: 10 },
      { value: '20', label: 20 },
      { value: '50', label: 50 },
      { value: '100', label: 100 },
    ],
  },
};

const RUBRIC_ADMIN_FIELDS = {
  type: {
    name: 'type',
    options: [
      { value: 'Department', label: 'Department' },
      { value: 'BestInCollege', label: 'Best In College' },
      { value: 'Innovation', label: 'Innovation' },
      { value: 'Sponsor', label: 'Sponsor' },
      { value: 'Other', label: 'Other' },
    ],
  },
  department: {
    name: 'department',
    options: [
      { value: 'CIS', label: 'CIS' },
      { value: 'ECE', label: 'ECE' },
      { value: 'ME', label: 'ME' },
      { value: 'IMSE', label: 'IMSE' },
    ],
  },
  course: {
    name: 'course',
    options: [
      { value: 'SD1', label: 'Senior Design 1' },
      { value: 'SD2', label: 'Senior Design 2' },
    ],
  },
  limit: {
    name: 'limit',
    options: [
      { value: '10', label: 10 },
      { value: '20', label: 20 },
      { value: '50', label: 50 },
      { value: '100', label: 100 },
    ],
  },
};

export {
  PROJECT_ADMIN_SECTIONS,
  PROJECT_ADMIN_FIELDS,
  RUBRIC_ADMIN_FIELDS,
  RUBRIC_ADMIN_SECTION,
};

import * as React from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Tooltip from '@mui/material/Tooltip';
import Avatar from '@mui/material/Avatar';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { useState, useEffect } from 'react';
import { useAuth } from '../../utils/AuthContext.js';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import {
  mainListItems,
  secondaryListItems,
} from '../../assets/judgeListItems.js';

function getColorForRole(role) {
  const colors = {
    ADMIN: '#1F6CFA',
    STUDENT: '#085C25',
    JUDGE: '#673AB7',
    FACULTY: '#1F6CFA',
  };
  return colors[role] || 'red'; // Default color if the role is not found
}

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  '& .MuiDrawer-paper': {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: 'border-box',
    ...(!open && {
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

export default function Dashboard({ Page, PageName }) {
  const [open, setOpen] = React.useState(true);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [searchParams] = useSearchParams();
  const { pid } = useParams();

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = (event) => {
    setAnchorElUser(null);
  };

  const { user, logout, isLoggedIn } = useAuth();
  const navigate = useNavigate();

  const settings = [
    {
      title: 'Dashboard',
      feature: function () {
        if (user?.role === 'FACULTY') {
          navigate(
            `/faculty/dashboard?department=${user?.department}&year=${user?.year}&course=SD2`
          );
          return;
        }
        if (user?.role === 'ADMIN') {
          navigate(`/admin/dashboard?year=${new Date().getFullYear()}`);
          return;
        }
        if (user?.role === 'JUDGE') {
          navigate(`/judge/dashboard`);
          return;
        }
      },
    },
    {
      title: 'Logout',
      feature: function () {
        logout();
        navigate('/portal/judge');
      },
    },
  ];

  const toggleDrawer = () => {
    setOpen(!open);
  };

  useEffect(() => {
    if (!isLoggedIn) {
      navigate('/');
    }
  }, [isLoggedIn, navigate, user]);

  return (
    <ThemeProvider theme={defaultTheme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar
          position="absolute"
          open={open}
          sx={{
            bgcolor: '#10284B',
            color: '#FFDB53',
          }}
        >
          <Toolbar
            sx={{
              pr: '24px', // keep right padding when drawer closed
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: '36px',
                ...(open && { display: 'none' }),
              }}
            >
              <MenuIcon />
            </IconButton>

            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1, ml: 3 }}
            >
              {PageName || 'Dashboard'}
            </Typography>

            <IconButton color="inherit" sx={{ mr: 2 }}>
              <Badge badgeContent={0} color="secondary">
                <NotificationsIcon />
              </Badge>
            </IconButton>

            <Box sx={{ flexGrow: 0 }}>
              <Tooltip title={isLoggedIn && 'Open settings'}>
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  {isLoggedIn ? (
                    <Avatar
                      sx={{
                        bgcolor: getColorForRole(user.role),
                        fontSize: '18px',
                      }}
                    >
                      {(user.firstName[0] + user.lastName[0]).toUpperCase()}{' '}
                    </Avatar>
                  ) : (
                    <Avatar src="/broken-image.jpg" />
                  )}
                </IconButton>
              </Tooltip>

              {isLoggedIn && (
                <Menu
                  sx={{ mt: '45px' }}
                  id="menu-appbars"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  MenuListProps={{ disablePadding: true }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                  disableScrollLock={true}
                >
                  {settings.map((setting) => (
                    <MenuItem
                      key={setting.title}
                      onClick={handleCloseUserMenu && setting.feature}
                    >
                      {setting.title}
                    </MenuItem>
                  ))}
                </Menu>
              )}
            </Box>
          </Toolbar>
        </AppBar>

        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              px: [1],
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <List component="nav">
            {mainListItems(user.course === 'SD2')}
            <Divider sx={{ my: 1 }} />
            {secondaryListItems(pid)}
          </List>
        </Drawer>

        <Page />
      </Box>
    </ThemeProvider>
  );
}

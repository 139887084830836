import { useEffect, useState } from 'react';
import DynamicContent from '../components/Atendee/DynamicContent';
import { getPage } from '../utils/API';

export default function PreviousYears() {
  const [content, setContent] = useState([]);
  const [loading, setLoading] = useState(true);
  const [infoBanner, setInfoBanner] = useState(null);

  useEffect(() => {
    getPage('previousYears')
      .then((data) => {
        setContent(data?.data);
        setInfoBanner(data?.infoBanner);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  }, []);

  return (
    <>
      <DynamicContent
        content={content}
        loading={loading}
        infoBanner={infoBanner}
        page="previousYears"
        setContent={setContent}
      />
    </>
  );
}

import { Box, Paper } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import NavBar from '../../components/AttendeeNavBar.js';
import { useAuth } from '../../utils/AuthContext.js';
import Card from '../../components/Card.js';
import TextBanner from '../../components/Atendee/UI/TextBanner.js';
import Footer from '../../components/Atendee/UI/Footer.js';

const Preset = {
  title: 'Faculty Portal',
  description: 'Navigate Your Senior Design Journey with Ease',
  portal: 'Portal',
  image: `https://senior-design-competion-production.nyc3.cdn.digitaloceanspaces.com/STATIC/logo512.png`,
  cardTitle: 'Faculty Portal',
  cardBody:
    'The UM-Dearborn Student SDC Portal is the centralized online platform for submitting senior design projects.',
  date: 'Welcome Back!',
  feature: 'Faculty Features',
};

const Portal = ({ content }) => {
  const { user, isLoggedIn } = useAuth();
  const navigate = useNavigate();

  // Handles the navigation to portal
  const handleNavigation = () => {
    if (isLoggedIn && user?.role === 'FACULTY') {
      navigate(
        `/faculty/dashboard?department=${user?.department}&year=${user?.year}&course=SD2`
      );
      return;
    }

    if (isLoggedIn && user?.role === 'ADMIN') {
      navigate(`/admin/dashboard?year=${new Date().getFullYear()}`);
      return;
    }

    navigate('/faculty/login');
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          gap: 4,
          alignItems: 'center',
          py: 3,
          m: 2,
        }}
      >
        <Card
          image={content.image}
          cardTitle={content.cardTitle}
          cardBody={content.cardBody}
          onClick={handleNavigation}
        />
        <Paper
          elevation={3}
          sx={{
            fontSize: '20px',
            p: 3,
          }}
        >
          {content.date}
        </Paper>
      </Box>
    </>
  );
};
const Faculty = () => {
  const [content] = useState(Preset);

  return (
    <>
      <NavBar />
      <TextBanner
        text="Faculty Portal"
        body="Navigate Your Senior Design Journey with Ease"
        bgcolor="#00274C"
        textColor="white"
      />
      <Portal content={content} />
      <TextBanner
        text="Faculty Features"
        body="The UM-Dearborn Faculty SDC Portal is the centralized online platform for managing senior design projects."
        bgcolor="#ffdb53"
        textColor="#00274c"
      />
      <Footer />
    </>
  );
};

export default Faculty;

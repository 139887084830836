import {
  Input,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';

export default function EditableRubric({ rubric, handleChange }) {
  return (
    <TableContainer component={Paper} sx={{ padding: 2, boxShadow: 4 }}>
      <Table sx={{ minWidth: 650, marginY: 2 }}>
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell>
              <emp>Weights</emp>
            </TableCell>
            {rubric?.scoreCategories.map((scoreCategory, index) => (
              <TableCell key={index}>
                <Input
                  value={scoreCategory}
                  onChange={(e) =>
                    handleChange('scoreCategories', index, e.target.value)
                  }
                >
                  {scoreCategory}
                </Input>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rubric?.rubricCategories.map((rubricCategory, rubricIndex) => (
            <TableRow key={rubricIndex}>
              <TableCell>
                <Input
                  multiline
                  value={rubricCategory}
                  onChange={(e) =>
                    handleChange(
                      'rubricCategories',
                      rubricIndex,
                      e.target.value
                    )
                  }
                >
                  {rubricCategory}
                </Input>
              </TableCell>
              <TableCell>
                <Input
                  value={rubric.weights[rubricIndex]}
                  type="number"
                  onChange={(e) =>
                    handleChange('weights', rubricIndex, e.target.value)
                  }
                >
                  {rubric.weights[rubricIndex]}
                </Input>
              </TableCell>
              {rubric.scoreCategories.map((scoreCategory, scoreIndex) => (
                <TableCell key={rubricIndex + ' ' + scoreIndex}>
                  <Input
                    value={
                      rubric.scoreCategoriesDescriptions[rubricIndex][
                        scoreIndex
                      ]
                    }
                    onChange={(e) =>
                      handleChange(
                        'scoreCategoriesDescription',
                        {
                          rubricCategory: rubricIndex,
                          scoreCategory: scoreIndex,
                        },
                        e.target.value
                      )
                    }
                    multiline
                  >
                    {
                      rubric.scoreCategoriesDescriptions[rubricIndex][
                        scoreIndex
                      ]
                    }
                  </Input>
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

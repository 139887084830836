import { Box, Button, Divider, Input } from '@mui/material';

const placeholders = {
  course: 'ACCEPTABLE VALUES: SD1, SD2, BOTH, N/A (CASE SENSITIVE)',
  department: 'ACCEPTABLE VALUES: CIS, IMSE, ME, ECE, N/A (CASE SENSITIVE)',
  year: 'Example: 2024',
  status: 'Judge Scoring Status: ACCEPTABLE VALUES: PENDING, COMPLETED',
  type: 'Rubric Type: ACCEPTABLE VALUES: Department, BestInCollege, Innovation, Sponsor, Other (CASE SENSITIVE)',
  role: 'User Role: ACCEPTABLE VALUES: ADMIN, JUDGE, STUDENT, FACULTY (CASE SENSITIVE)',
};

const invalid = (field, value) => {
  if (field === 'course') {
    return (
      value !== 'SD1' && value !== 'SD2' && value !== 'BOTH' && value !== 'N/A'
    );
  }
  if (field === 'department') {
    return (
      value !== 'CIS' &&
      value !== 'IMSE' &&
      value !== 'ME' &&
      value !== 'ECE' &&
      value !== 'N/A'
    );
  }
  if (field === 'status') {
    return value !== 'PENDING' && value !== 'COMPLETED';
  }
  if (field === 'type') {
    return (
      value !== 'Department' &&
      value !== 'BestInCollege' &&
      value !== 'Innovation' &&
      value !== 'Sponsor' &&
      value !== 'Other'
    );
  }
  if (field === 'year') {
    return isNaN(value) || value.length !== 4;
  }

  if (field === 'role') {
    return (
      value !== 'ADMIN' &&
      value !== 'JUDGE' &&
      value !== 'STUDENT' &&
      value !== 'FACULTY'
    );
  }

  return false;
};

export default function Form({ fieldsList, fields, setFields, handleSubmit }) {
  const disabled = fieldsList.some((field) => invalid(field, fields[field]));
  return (
    <Box>
      {fieldsList &&
        fieldsList.map((field) => {
          return (
            <Box key={field} padding={1} gap={2} display="flex">
              {field?.toUpperCase()}
              <Input
                key={field}
                value={fields[field]}
                onChange={(e) =>
                  setFields({ ...fields, [field]: e.target.value })
                }
                placeholder={placeholders[field]}
                fullWidth
              />
            </Box>
          );
        })}
      <Button
        variant="contained"
        color="primary"
        sx={{ width: '100%' }}
        size="large"
        disabled={disabled}
        onClick={handleSubmit}
      >
        Generate Report
      </Button>
    </Box>
  );
}

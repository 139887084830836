import { Box, Divider, Typography } from '@mui/material';
import { StylesForm } from '../../../styles/global';

// Widget for Poster Image
const InformPosterImage = ({ project, dueDate }) => {
  return (
    <>
      {project && (
        <>
          {/* Project Title + Due Date */}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            {/* Project Title */}
            <Typography {...StylesForm.text('h6')}>
              {project.data.title.length > 50
                ? `${project.data.title.substring(0, 50)}... Poster Image`
                : `${project.data.title} Poster Image`}
            </Typography>
            <Divider />

            {/* Due Date */}
            <Typography
              {...StylesForm.text('p')}
              sx={{
                marginBottom: 1,
                color: '#226E93',
              }}
            >
              (due {dueDate || 'unassigned'})
            </Typography>
          </Box>
          <Divider />

          {/* Image Status */}
          <Typography
            {...StylesForm.text('p')}
            sx={{
              marginBottom: 1,
              color: '#226E93',
            }}
          >
            Status:{' '}
            {project.data.posterImage === 'CIS_Default.png' ||
            project.data.posterImage === 'ECE_Default.png' ||
            project.data.posterImage === 'IMSE_Default.png' ||
            project.data.posterImage === 'ME_Default.png'
              ? 'Default Image'
              : 'Uploaded Image'}
          </Typography>

          {/* Poster Image *** */}
          <img
            alt="poster"
            src={`${process.env.REACT_APP_SPACES_ENDPOINT_POSTER_IMAGES}${project.data.posterImage}`}
          />
        </>
      )}
    </>
  );
};

export default InformPosterImage;

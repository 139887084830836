import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
export default function ImageAccordian({ title, imgSrc, imgAlt }) {
  return (
    <Accordion>
      <AccordionSummary>{title}</AccordionSummary>
      <AccordionDetails>
        <img style={{ width: '100%' }} src={imgSrc} alt={imgAlt} />
      </AccordionDetails>
    </Accordion>
  );
}

import {
  Box,
  Input,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import { useState } from 'react';

export default function Text({ component, handleChange }) {
  const [localContent, setLocalContent] = useState(component?.content || '');
  function handleVariantChange(e) {
    const newComponent = { ...component, variant: e.target.value };
    handleChange(newComponent);
  }
  function handleContentChange() {
    const newComponent = { ...component, content: localContent };
    handleChange(newComponent);
  }
  return (
    <Box display="flex" flexDirection={'column'} gap={2}>
      <h5>Variant</h5>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Variant</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={component?.variant || ''}
          label="Variant"
          onChange={handleVariantChange}
        >
          <MenuItem value={'h1'}>H1</MenuItem>
          <MenuItem value={'h2'}>H2</MenuItem>
          <MenuItem value={'h3'}>H3</MenuItem>
          <MenuItem value={'h4'}>H4</MenuItem>
          <MenuItem value={'h5'}>H5</MenuItem>
          <MenuItem value={'h6'}>H6</MenuItem>
          <MenuItem value={'subtitle1'}>Subtitle1</MenuItem>
          <MenuItem value={'subtitle2'}>Subtitle2</MenuItem>
          <MenuItem value={'body1'}>Body1</MenuItem>
          <MenuItem value={'body2'}>Body2</MenuItem>
          <MenuItem value={'caption'}>Caption</MenuItem>
          <MenuItem value={'button'}>Button</MenuItem>
          <MenuItem value={'overline'}>Overline</MenuItem>
        </Select>
      </FormControl>
      <h5>Content</h5>
      <Input
        value={localContent}
        fullWidth
        multiline
        onChange={(e) => setLocalContent(e.target.value)}
        onBlur={() => handleContentChange()}
      />
    </Box>
  );
}

import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Divider } from '@mui/material';
import BasicProjectEntity from './Entity/BasicProject';

export default function FacultyAccordian({ title, CIS, ECE, ME, IMSE }) {
  return (
    <Box padding={2} margin={3}>
      <h3>{title}</h3>
      <Divider />
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          CIS: {`(Results: ${CIS?.length || 0})`}
        </AccordionSummary>
        <AccordionDetails>
          {CIS && (
            <>
              {CIS.map((project, index) => (
                <BasicProjectEntity key={index} project={project} />
              ))}
            </>
          )}
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
        >
          ECE: {`(Results: ${ECE?.length || 0})`}
        </AccordionSummary>
        <AccordionDetails>
          {ECE && (
            <>
              {ECE.map((project, index) => (
                <BasicProjectEntity key={index} project={project} />
              ))}
            </>
          )}
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3-content"
          id="panel3-header"
        >
          ME: {`(Results: ${ME?.length || 0})`}
        </AccordionSummary>
        <AccordionDetails>
          {ME && (
            <>
              {ME.map((project, index) => (
                <BasicProjectEntity key={index} project={project} />
              ))}
            </>
          )}
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4-content"
          id="panel4-header"
        >
          IMSE: {`(Results: ${IMSE?.length || 0})`}
        </AccordionSummary>
        <AccordionDetails>
          {IMSE && (
            <>
              {IMSE.map((project, index) => (
                <BasicProjectEntity key={index} project={project} />
              ))}
            </>
          )}
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}

import { ExpandMore } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Container,
  Divider,
  Typography,
} from '@mui/material';

const AccordionImage = ({ imageSrc, imageAlt, title, autoExpand = false }) => {
  // Accordion with arrow
  return (
    <Container maxWidth="lg" sx={{ marginY: 1 }}>
      <Accordion defaultExpanded={autoExpand}>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Typography variant="h6" color="#0F284B ">
            {title}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Divider />
          <img
            src={imageSrc}
            alt={imageAlt}
            style={{
              width: '100%',
            }}
          />
        </AccordionDetails>
      </Accordion>
    </Container>
  );
};

export default AccordionImage;

import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Divider } from '@mui/material';
import BasicProjectEntity from './Entity/BasicProject';

export default function CourseAccordian({ title, SD1, SD2 }) {
  return (
    <Box padding={2} margin={3}>
      <h3>{title}</h3>
      <Divider />
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          Senior Design 1: {`(Results: ${SD1?.length || 0})`}
        </AccordionSummary>
        <AccordionDetails>
          {SD1 && (
            <>
              {SD1.map((project, index) => (
                <BasicProjectEntity key={index} project={project} />
              ))}
            </>
          )}
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
        >
          Senior Design 2: {`(Results: ${SD2?.length || 0})`}
        </AccordionSummary>
        <AccordionDetails>
          {SD2 && (
            <>
              {SD2.map((project, index) => (
                <BasicProjectEntity key={index} project={project} />
              ))}
            </>
          )}
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}

export default function Youtube({ videoLink }) {
  return (
    <iframe
      src={`${videoLink}`}
      title="YouTube video player"
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      width="560"
      height="315"
    ></iframe>
  );
}

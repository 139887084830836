import {
  Box,
  Input,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import { useState } from 'react';

export default function Alert({ component, handleChange }) {
  const [localContent, setLocalContent] = useState(component?.message || '');
  function handleVariantChange(e) {
    const newComponent = { ...component, severity: e.target.value };
    handleChange(newComponent);
  }
  function handleContentChange() {
    const newComponent = { ...component, message: localContent };
    handleChange(newComponent);
  }
  return (
    <Box display="flex" flexDirection={'column'} gap={2}>
      <h5>Variant</h5>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Variant</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={component?.severity || ''}
          label="Severity"
          onChange={handleVariantChange}
        >
          <MenuItem value={'error'}>Error</MenuItem>
          <MenuItem value={'warning'}>Warning</MenuItem>
          <MenuItem value={'info'}>Info</MenuItem>
          <MenuItem value={'success'}>Success</MenuItem>
        </Select>
      </FormControl>
      <h5>Message</h5>
      <Input
        value={localContent}
        fullWidth
        multiline
        onChange={(e) => setLocalContent(e.target.value)}
        onBlur={() => handleContentChange()}
      />
    </Box>
  );
}

import { useState } from 'react';
import Box from '@mui/material/Box';
import MuModal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import {
  Alert,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  TextField,
} from '@mui/material';
import { deleteUser, updateUser } from '../utils/API';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: { lg: 800, md: 600, sm: 'auto' },
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  overflow: 'auto',
  borderRadius: '7px',
  maxHeight: '80%',
  overflowY: 'auto',
};

function DeleteUser({ user, flag, setFlag, setOpen }) {
  const [checked, setChecked] = useState(false);

  const handleDelete = async () => {
    try {
      await deleteUser(user._id);
      alert('User deleted successfully');
      setFlag(!flag);
      setOpen(false);
    } catch (error) {
      alert(error?.response?.data?.message || 'An error occurred');
    }
  };

  return (
    <Box>
      <br />
      <h2> Delete User</h2>
      <Alert severity="error">
        Are you sure you want to delete this user? This action cannot be undone.
        For a student, this will remove them from all projects they are a part
        of. If they are the sole owner of a project, the project will be
        deleted. It will also delete all the scores and judge assignments
        associated with it. For a judge, this will remove them from all projects
        they are judging and the scores they made. For a faculty/admin user,
        this will erase all the tutorials they have created.
      </Alert>
      <FormGroup sx={{ marginY: 1, marginX: 2 }}>
        <FormControlLabel
          control={<Checkbox />}
          required
          label="I understand the consequences of this action."
          value={checked}
          onChange={(e) => setChecked(e.target.checked)}
        />
      </FormGroup>
      <Button
        variant="contained"
        color="error"
        sx={{ marginTop: 1, marginBottom: 2, width: '100%' }}
        disabled={!checked}
        onClick={handleDelete}
      >
        Delete
      </Button>
    </Box>
  );
}

function UserForm({ user, flag, setFlag, setOpen }) {
  const [values, setValues] = useState({
    firstName: user?.firstName || '',
    lastName: user?.lastName || '',
    department: user?.department || '',
    course: user?.course || '',
    role: user?.role || '',
    year: user?.year || '',
    umichID: user?.umichID || '',
  });
  const setField = (field, value) => {
    setValues({ ...values, [field]: value });
  };

  const changeOccured = Object.keys(values).some(
    (key) => values[key] !== user[key]
  );

  const validRole = ['STUDENT', 'JUDGE', 'FACULTY', 'ADMIN'].includes(
    values.role
  );
  const validDepartment = ['CIS', 'ECE', 'IMSE', 'ME', 'N/A'].includes(
    values.department
  );
  const validCourse = ['SD1', 'SD2', 'BOTH', 'N/A'].includes(values.course);
  const valid =
    values.firstName &&
    values.lastName &&
    validRole &&
    validDepartment &&
    validCourse &&
    changeOccured;

  const handleSubmit = async () => {
    try {
      const temp = { ...values };
      temp.year = parseInt(temp.year);
      temp.umichID = parseInt(temp.umichID);
      await updateUser(user._id, temp);
      alert('User updated successfully');
      setFlag(!flag);
      setOpen(false);
    } catch (error) {
      alert(error?.response?.data?.message || 'An error occurred');
    }
  };

  return (
    <Box>
      <h2> Edit User</h2>
      <Divider />
      <TextField
        label="First Name"
        value={values.firstName}
        onChange={(e) => setField('firstName', e.target.value)}
        fullWidth
        sx={{ marginTop: 2, marginBottom: 1 }}
      />
      <TextField
        label="Last Name"
        value={values.lastName}
        onChange={(e) => setField('lastName', e.target.value)}
        fullWidth
        sx={{ marginY: 1 }}
      />
      <TextField
        label="Department"
        value={values.department}
        onChange={(e) => setField('department', e.target.value)}
        fullWidth
        sx={{ marginY: 1 }}
        placeholder="ACCEPTABLE VALUES: CIS, ECE, IMSE, ME, N/A"
      />
      <TextField
        label="Course"
        value={values.course}
        onChange={(e) => setField('course', e.target.value)}
        fullWidth
        sx={{ marginY: 1 }}
        placeholder="ACCEPTABLE VALUES: SD1, SD2, BOTH, N/A"
      />
      <TextField
        label="Role"
        value={values.role}
        onChange={(e) => setField('role', e.target.value)}
        fullWidth
        sx={{ marginY: 1 }}
        placeholder="ACCEPTABLE VALUES: STUDENT, JUDGE, FACULTY, ADMIN"
      />
      <TextField
        label="Year"
        value={values.year}
        onChange={(e) => setField('year', e.target.value)}
        fullWidth
        sx={{ marginY: 1 }}
      />
      <TextField
        label="UMICH ID"
        value={values.umichID}
        onChange={(e) => setField('umichID', e.target.value)}
        fullWidth
        sx={{ marginTop: 1 }}
      />
      <Button
        variant="contained"
        color="primary"
        sx={{ marginY: 2, width: '100%' }}
        disabled={!valid}
        onClick={handleSubmit}
      >
        Save
      </Button>
    </Box>
  );
}

export default function UserModal({
  user,
  flag,
  setFlag,
  width = { lg: 800, md: 600, sm: 'auto' },
}) {
  const [open, setOpen] = useState(false);
  return (
    <div>
      <Button
        on
        variant="outlined"
        color="success"
        onClick={() => setOpen(true)}
      >
        Actions
      </Button>
      <MuModal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ overflow: 'auto' }}
      >
        <Box sx={{ ...style, width: width }}>
          <UserForm
            user={user}
            flag={flag}
            setFlag={setFlag}
            setOpen={setOpen}
          />

          <Divider />

          <DeleteUser
            user={user}
            flag={flag}
            setFlag={setFlag}
            setOpen={setOpen}
          />

          <Divider />

          <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 2 }}>
            <Button
              onClick={() => setOpen(false)}
              variant="contained"
              color="info"
            >
              close
            </Button>
          </Box>
        </Box>
      </MuModal>
    </div>
  );
}

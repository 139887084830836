import { Select, Radio, Text } from '../UI';

const FilterComponent = ({ field, value, name, handleChange }) => {
  switch (field.type) {
    case 'select':
      return (
        <Select
          value={value}
          handleChange={handleChange}
          options={field.getOptions()}
          label={field.name}
          name={name}
        />
      );
    case 'radio':
      return (
        <Radio
          value={value}
          handleChange={handleChange}
          options={field.getOptions()}
          label={field.name}
          name={name}
        />
      );
    case 'text':
      return (
        <Text
          value={value}
          handleChange={handleChange}
          label={field.name}
          name={name}
        />
      );
    default:
      return <></>;
  }
};

export default FilterComponent;

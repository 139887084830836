import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Avatar } from '@mui/material';
import UserModal from './UserModal';

export default function UsersTable({ rows, flag, setFlag, adminMode = false }) {
  return (
    <TableContainer component={Paper} sx={{ p: '20px' }}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Users</TableCell>
            <TableCell align="right">Full Name</TableCell>
            <TableCell align="right">Email</TableCell>
            <TableCell align="right">Department</TableCell>
            <TableCell align="right">Course</TableCell>
            <TableCell align="right">Role</TableCell>
            <TableCell align="right">UMICH ID</TableCell>
            {adminMode && <TableCell align="right"></TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.fullName}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                <Avatar alt={row?.fullName} src={row?.avatar} />
              </TableCell>
              <TableCell align="right">{row?.fullName}</TableCell>
              <TableCell align="right">{row?.email}</TableCell>
              <TableCell align="right">{row?.department}</TableCell>
              <TableCell align="right">{row?.course}</TableCell>
              <TableCell align="right">{row?.role}</TableCell>
              <TableCell align="right">{row?.umichID || 'N/A'}</TableCell>
              {adminMode && (
                <TableCell align="right">
                  <UserModal user={row} flag={flag} setFlag={setFlag} />
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

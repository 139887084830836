import * as React from 'react';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Dashboard from '../../../components/Student/Dashboard.js';
import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Styles, StylesForm } from '../../../styles/global.js';

import { Alert, TextField, Typography, Divider, Button } from '@mui/material';
import {
  getMainConfig,
  getProject,
  updateAbstract,
} from '../../../utils/API.js';
import { formatDate } from '../../../utils/dates.js';
import { InformProjectAbstract } from '../../../components/Student/Widgets';

// Form: Submit Abstract
const SubmitAbstract = ({ setFeedback, setProject }) => {
  const { pid } = useParams();
  const [abstract, setAbstract] = useState('');

  const handleSubmission = async () => {
    try {
      const response = await updateAbstract(pid, abstract);

      if (response.data.success) {
        setFeedback({ severity: 'success', message: response.data.message });
        try {
          const response = await getProject(pid);
          setProject(response.data);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setFeedback({
        severity: 'error',
        message: error.response.data.errMessage,
      });
    }
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        {/* Title; Submit Abstract */}
        <Typography variant="h6" margin={1}>
          Submit Abstract
        </Typography>

        <Divider />

        {/* Button: Submit Abstract */}
        <Button
          variant="outlined"
          disabled={abstract.length === 0}
          onClick={handleSubmission}
          sx={{ marginBottom: 1 }}
        >
          Submit
        </Button>
      </Box>

      <Divider />

      {/* Input: Abstract Submit */}
      <TextField
        {...StylesForm.input('submitAbstract', 'Max 250 Words')}
        InputProps={{
          rows: 9,
          multiline: true,
          inputComponent: 'textarea',
          maxLength: 600,
        }}
        value={abstract}
        onChange={(e) => setAbstract(e.target.value)}
      />
    </>
  );
};

// Widget to Feedback Inform
const InformFeedback = ({ feedback }) => {
  return (
    <>
      {/* Title: Submission Status */}
      <Typography variant="h6" margin={1}>
        Submission Status
      </Typography>

      <Divider />

      {/* Content: Feedback */}
      {feedback ? (
        <Alert severity={feedback.severity}> {feedback.message} </Alert>
      ) : (
        <></>
      )}
    </>
  );
};

// Widget to provide basic guidelines for the abstract submissions.
const InformAbstractGuidelines = () => {
  return (
    <>
      {/* Title: Abstract Information */}
      <Typography variant="h6" margin={1}>
        Abstract Information
      </Typography>

      <Divider />

      {/* Paragraph: Abstract Summary and Rules */}
      <Typography
        variant="p"
        margin={1}
        sx={{
          marginBottom: 2,
        }}
      >
        The abstract is a comprehensive summary for your project. There is a
        maximum 600 characters limit for submission.
      </Typography>

      {/* Paragraph: Abstract Summary and Rules */}
      <Typography variant="h6" margin={1}>
        Abstract Suggestions
      </Typography>

      <Divider />

      {/* List: Suggestions && Rules*/}
      <ul style={{ marginTop: 4 }}>
        <li>Check the Rubric Associated with your Deparment and Course.</li>
        <li>Make sure the Abstract is at or close to the 250 words.</li>
      </ul>
    </>
  );
};

// Widget for No Project Selected Notice
const InformNull = () => {
  return (
    <>
      {/* Title: No Project Notice */}
      <Typography variant="h6" margin={1}>
        No Project Selected Notice
      </Typography>

      <Divider />

      {/* Paragraph: No Selected Project */}
      <Typography
        {...StylesForm.text('p')}
        sx={{
          marginBottom: 1,
        }}
      >
        No project is currently selected.
      </Typography>

      {/* Due Date */}
      <Typography
        {...StylesForm.text('p')}
        sx={{
          marginBottom: 3,
          color: '#226E93',
        }}
      >
        Please select a project to submit an abstract.
      </Typography>

      {/* Title: Resolution Options*/}
      <>
        <Typography variant="h6" margin={1}>
          Options for Resolution
        </Typography>
        <Divider />
        <ul style={{ marginTop: 4 }}>
          <li>Create a Project in the Dashboard Page.</li>
          <li>Select an Existing Project from the Project Dropdown Menu.</li>
        </ul>
      </>
    </>
  );
};

// Page that shows when PID NOT == 0
const ExistingProjectAbstract = () => {
  const [feedback, setFeedback] = useState(null);
  const [project, setProject] = useState(null);
  const [config, setConfig] = useState(null);
  const { pid } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getProject(pid);
        setProject(response.data);
        const configResponse = await getMainConfig();
        setConfig(configResponse?.data?.data);
      } catch (error) {
        navigate('/student/dashboard/project/0/abstract');
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [pid, navigate]);

  return (
    <>
      <Box {...Styles.mainBox}>
        <Toolbar />

        {/* Container With Max Width = lg breakpoint */}
        <Container {...Styles.customContainer()}>
          {/*Outer Grid Container */}

          <Grid container spacing={4}>
            {/*Inner Grid Begins*/}

            {/*Inner Grid Item 1: Project Abstract Form Title*/}
            <Grid item lg={12} sm={12} xs={12}>
              <Paper {...Styles.dashboardPageWidget()}>
                Project Abstract Submission
              </Paper>
            </Grid>

            {/*Inner Grid Item 2: THE ABSTRACT*/}
            <Grid item lg={7} md={12} sm={12} xs={12}>
              <Paper {...Styles.dashboardContentWidget()}>
                <InformProjectAbstract
                  project={project}
                  dueDate={formatDate(config?.dueDates?.abstract)}
                />
              </Paper>
            </Grid>

            {/*Inner Grid Item 3: General Abstract Information*/}
            <Grid item lg={5} md={12} sm={12} xs={12}>
              <Paper {...Styles.dashboardContentWidget()}>
                <InformAbstractGuidelines />
              </Paper>
            </Grid>

            {/*Inner Grid Item 4: Submit Abstract Form*/}
            <Grid item lg={7} md={12} sm={12} xs={12}>
              <Paper {...Styles.dashboardContentWidget()}>
                <SubmitAbstract
                  project={project}
                  setProject={setProject}
                  feedback={feedback}
                  setFeedback={setFeedback}
                />
              </Paper>
            </Grid>

            {/*Inner Grid Item 5: Feedback Widget */}
            <Grid item lg={5} md={12} sm={12} xs={12}>
              <Paper {...Styles.dashboardContentWidget()}>
                <InformFeedback feedback={feedback} />
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

// Page that shows when PID == 0
const NewProjectAbstract = () => {
  return (
    <>
      <Box {...Styles.mainBox}>
        <Toolbar />

        {/* Container With Max Width = lg breakpoint */}
        <Container {...Styles.customContainer()}>
          {/*Outer Grid Container */}

          <Grid container spacing={4}>
            {/*Inner Grid Begins*/}
            {/*Inner Grid Item 1: Project Abstract Form Title*/}
            <Grid item lg={12} sm={12} xs={12}>
              <Paper {...Styles.dashboardPageWidget()}>
                Project Abstract Submission
              </Paper>
            </Grid>

            {/*Inner Grid Item 2: No Project Selected Notice*/}
            <Grid item lg={6} md={12} sm={12} xs={12}>
              <Paper {...Styles.dashboardContentWidget()}>
                <InformNull />
              </Paper>
            </Grid>

            {/*Inner Grid Item 3: General Abstract Information*/}
            <Grid item lg={6} md={12} sm={12} xs={12}>
              <Paper {...Styles.dashboardContentWidget()}>
                <InformAbstractGuidelines />
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default function ProjectAbstract() {
  const { pid } = useParams();

  return Number(pid) !== 0 ? (
    <Dashboard Page={ExistingProjectAbstract} PageName={'Abstract'} />
  ) : (
    <Dashboard Page={NewProjectAbstract} PageName={'Abstract'} />
  );
}

import * as React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

export default function ImgMediaCard({ image, cardTitle, cardBody, onClick }) {
  return (
    <Card
      sx={{
        maxWidth: 445,
        m: 4,
        cursor: "pointer",
        transition: "box-shadow bgcolor 0.3s",
        "&:hover": {
          boxShadow: 20,
        },
      }}
      onClick={onClick}
    >
      <CardMedia
        component="img"
        alt="portal"
        height="240"
        image={image}
      />
      <CardContent
        sx={{
          backgroundColor: "#0F284B",
          color: "white",
        }}
      >
        <Typography
          gutterBottom
          variant="h5"
          component="div"
        >
          {cardTitle}
        </Typography>
        <Typography variant="body1">{cardBody}</Typography>
      </CardContent>
    </Card>
  );
}

import React from 'react'; // Add missing import
import {
  Box,
  Typography,
  Button,
  Skeleton,
  TextField,
  InputAdornment,
  IconButton,
} from '@mui/material';

import { Send } from '@mui/icons-material';

import { useState, useEffect, useRef } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { askChatbot, getMainConfig } from '../utils/API';

function generateRandomId() {
  const randomPart = Math.random().toString(36).substr(2, 9);
  const timestampPart = Date.now().toString(36);
  return randomPart + timestampPart;
}

const ChatbotIcon = ({ visible, setVisible }) => {
  const [slideBack, setSlide] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (slideBack === false) {
        setSlide(!slideBack);
      }
    }, 1000);
    return () => clearTimeout(timer);
  }, [slideBack]);

  return (
    <>
      <Box
        sx={{
          position: 'fixed',
          bottom: 0,
          right: 0,
          '&:hover': {
            boxShadow: '0px 0px 5px 2px rgba(0, 0, 0, 0.2)',
          },
          marginBottom: '20px',
          marginRight: '20px',
          transition: 'transform 0.5s ease-in-out',
          transform: slideBack ? 'translateY(0)' : 'translateY(150%)',
        }}
      >
        {!visible && (
          <Button onClick={() => setVisible(!visible)}>
            <img
              visible={visible}
              alt="Chatbot"
              src="/images/chatbot.PNG"
              style={{
                width: '60px',
                height: '60px',
              }}
            />
          </Button>
        )}
      </Box>
    </>
  );
};

const ChatbotTitle = ({ visible, setVisible, slide, setSlide }) => {
  const MainLayout = {
    position: 'static',
    backgroundColor: 'white',
    height: '15%',
    width: '100%',
    borderTopLeftRadius: '20px',
    borderTopRightRadius: '20px',
    boxShadow: 3,
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    gap: 5,
  };
  return (
    <>
      <Box sx={MainLayout}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 2,
          }}
        >
          <img
            alt="Chatbot"
            src="images/chatbot.PNG"
            style={{
              width: '60px',
              height: '60px',
            }}
          />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Typography variant="h5">ChatBot</Typography>
            <Typography
              variant="caption"
              sx={{
                marginLeft: 0.3,
                color: 'green',
              }}
            >
              Online
            </Typography>
          </Box>
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: 1,
          }}
        >
          <CloseIcon
            onClick={() => {
              setVisible(!visible);
              setSlide(!slide);
            }}
            sx={{
              cursor: 'pointer',
            }}
          />

          <Typography variant="caption">Close</Typography>
        </Box>
      </Box>
    </>
  );
};

const Messages = ({ message, interactions, setInteractions }) => {
  const BotContext = {
    display: 'flex',
    gap: 1,
    alignItems: 'center',
    marginLeft: 3,
    marginTop: 2,
  };

  const UserContext = {
    display: 'flex',
    gap: 1,
    alignItems: 'center',
    marginRight: 3,
    marginTop: 2,
    justifyContent: 'flex-end',
  };

  const BotMsg = {
    display: 'flex',
    gap: 1,
    alignItems: 'center',
    marginLeft: 3,
    my: 0.8,
  };

  const UserMsg = {
    display: 'flex',
    gap: 1,
    alignItems: 'center',
    marginRight: 1,
    marginLeft: 3,
    my: 0,
    justifyContent: 'flex-end',
  };

  const [loading, setLoading] = useState(message.message === '' ? true : false);
  const [isChatbot] = useState(message.actor === 'Chatbot' ? true : false);

  useEffect(() => {
    if (
      message.actor === 'User' &&
      interactions[interactions.length - 1].actor === 'User'
    ) {
      setInteractions([
        ...interactions,
        {
          actor: 'Chatbot',
          message: '',
          question: message.message,
          id: generateRandomId(),
        },
      ]);
    }
    if (message.actor === 'Chatbot' && message.message === '') {
      askChatbot(message)
        .then((response) => {
          setLoading(false);
          setInteractions((prevInteractions) => {
            return prevInteractions.map((interaction) => {
              if (interaction.id === message.id) {
                return {
                  ...interaction,
                  message: response.data.responseFromChatGPT,
                };
              }
              console.log(interactions);
              return interaction; //
            });
          });
        })
        .catch((err) => {
          setInteractions((prevInteractions) => {
            return prevInteractions.map((interaction) => {
              if (interaction.id === message.id) {
                return {
                  ...interaction,
                  message: 'A Server Error Occurred. Please Try Again Later!',
                };
              }
              console.log(interactions);
              return interaction; //
            });
          });
          setLoading(false);
        });
    }
  }, []);

  return (
    <>
      {isChatbot ? (
        <>
          <Box sx={BotContext}>
            <img
              alt="Chatbot"
              src="images/chatbot.PNG"
              style={{
                width: '20px',
                height: '20px',
              }}
            />
            <Typography
              variant="caption"
              sx={{
                fontSize: '10px',
              }}
            >
              ChatBot
            </Typography>
          </Box>
          <Box sx={BotMsg}>
            {loading ? (
              <Skeleton animation="wave" width={250} height={45} sx={{}} />
            ) : (
              <Typography
                variant="caption"
                sx={{
                  borderRadius: 2,
                  bgcolor: 'white',
                  px: 1,
                  py: 1,
                  marginRight: 3,
                  lineHeight: 1.3,
                }}
              >
                {message.message}
              </Typography>
            )}
          </Box>
        </>
      ) : (
        <>
          <Box sx={UserContext}>
            <Typography
              variant="caption"
              sx={{
                fontSize: '10px',
                lineHeight: 1,
              }}
            >
              You
            </Typography>
          </Box>
          <Box sx={UserMsg}>
            {loading ? (
              <Skeleton animation="wave" width={250} height={45} sx={{}} />
            ) : (
              <Typography
                variant="caption"
                sx={{
                  borderRadius: 1,
                  px: 1,
                  py: 1,
                  marginRight: 2,
                  lineHeight: 1.3,
                  bgcolor: 'rgb(0, 102, 255)',
                  color: 'white',
                }}
              >
                {message.message}
              </Typography>
            )}
          </Box>
        </>
      )}
    </>
  );
};

const ChatbotBody = ({
  visible,
  setVisible,
  interactions,
  setInteractions,
}) => {
  const MainLayout = {
    position: 'static',
    backgroundColor: 'rgb(234, 238, 243);',
    height: '75%',
    width: '100%',
    boxShadow: 3,
    overflow: 'auto',
  };

  const containerRef = useRef(null);

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  }, [interactions]);

  return (
    <>
      <Box sx={MainLayout} ref={containerRef}>
        {interactions.map((interaction) => (
          <Messages
            key={interaction.id}
            message={interaction}
            interactions={interactions}
            setInteractions={setInteractions}
          />
        ))}
        {/* <Messages
          message={{
            m: "Hi HI HELLO HELLO HELLO HELLO",
            actor: "Chatbot",
          }}
        />
        <Messages
          message={{
            m: "Hi",
            actor: "me",
          }}
        /> */}
        {/* <Typography
          variant="p"
          sx={{
            margin: 3,
          }}
        >
          Curiosity, often hailed as the cornerstone of human progress, is a
          force that propels us towards discovery, innovation, and
          enlightenment. From the dawn of civilization to the contemporary era,
          it has been curiosity that has driven humanity to explore the depths
          of the oceans, reach for the stars, and unravel the mysteries of the
          cosmos. In its essence, curiosity is an insatiable thirst for
          knowledge, a relentless urge to understand the world and our place
          within it. One of the most remarkable aspects of curiosity is its
          ability to transcend boundaries. It knows no limits of age, gender, or
          background. From the inquisitive minds of children asking endless
          questions to the seasoned scientists probing the intricacies of the
          universe, curiosity is a trait inherent in all of us. It is the spark
          that ignites the flame of discovery, driving us to explore the unknown
          and challenge the status quo. At its core, curiosity is fueled by
          wonder and fascination. It compels us to look beyond the surface, to
          delve deeper into the mysteries that surround us. Whether it's
          pondering the workings of the human mind or contemplating the vastness
          of the cosmos, curiosity prompts us to seek answers and unravel the
          secrets of existence. Moreover, curiosity is the driving force behind
          innovation and progress. Countless inventions and discoveries
          throughout history can be attributed to the curiosity-driven endeavors
          of individuals who dared to question the norm and explore new
          possibilities. From the invention of the wheel to the development of
          modern technology, curiosity has been the catalyst for human
          advancement, pushing the boundaries of what is possible and reshaping
          the world in profound ways. Furthermore, curiosity fosters empathy and
          understanding. By prompting us to explore different perspectives and
          experiences, it enables us to connect with others on a deeper level
          and cultivate a sense of empathy and compassion. In a world often
          divided by differences, curiosity serves as a bridge that brings
          people together, fostering dialogue, mutual respect, and appreciation
          for diversity. In conclusion, curiosity is not merely a trait but a
          driving force that shapes our understanding of the world and drives us
          towards progress and enlightenment. It is a quality that should be
          nurtured and celebrated, for it is through curiosity that we unlock
          the door to endless possibilities and embark on a journey of discovery
          and growth.
        </Typography> */}
      </Box>
    </>
  );
};

const ChatbotBottom = ({
  visible,
  setVisible,
  interactions,
  setInteractions,
}) => {
  const MainLayout = {
    backgroundColor: 'white',
    height: '10%',
    width: '100%',
    boxShadow: '3',
    borderBottomLeftRadius: '20px',
    borderBottomRightRadius: '20px',
  };

  const [input, setInput] = useState('');

  const handleSend = () => {
    if (input.length > 0) {
      setInteractions([
        ...interactions,
        { actor: 'User', message: input, id: generateRandomId() },
      ]);
      setInput('');
    }
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          boxShadow: '2',
          borderBottomLeftRadius: '10px',
          borderBottomRightRadius: '10px',
        }}
      >
        <TextField
          id="standard-basic"
          variant="outlined"
          size="small"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          onKeyPress={(e) => {
            if (e.key === 'Enter' && input.length > 0) {
              setInteractions([
                ...interactions,
                { actor: 'User', message: input, id: generateRandomId() },
              ]);
              setInput('');
            }
          }}
          sx={{
            marginLeft: 0,
            height: '50%',
            width: '100%',
            '& input': {
              fontSize: '11px',
            },
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="send message"
                  onClick={handleSend}
                  sx={{
                    color: input.length > 0 ? 'blue' : 'inherit',
                    transition: 'color 0.3s ease',
                    marginRight: '4px',
                  }}
                >
                  <Send />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Box sx={{ m: 2 }}>
          <Typography variant="caption">
            Powered by <span style={{ fontWeight: 'bold' }}>AI</span>
          </Typography>
        </Box>
      </Box>
    </>
  );
};

const ChatbotScreen = ({
  visible,
  setVisible,
  interactions,
  setInteractions,
}) => {
  const [slide, setSlide] = useState(false);

  const MainLayout = {
    position: 'fixed',
    bottom: 0,
    right: 0,
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '20px',
    marginRight: '20px',
    width: '18vw',
    height: '65vh',
    minWidth: '300px',
    minHeight: '500px',
    bgcolor: '#F8F8F8',
    borderRadius: '30px',
    transition: 'transform 1s ease-in-out',
    transform: !slide ? 'translateY(100%)' : 'translateY(0)',
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      if (slide === false) {
        setSlide(!slide);
      }
    }, 1000);
    return () => clearTimeout(timer);
  }, [slide]);

  return (
    <>
      {visible && (
        <Box sx={MainLayout}>
          <ChatbotTitle
            visible={visible}
            setVisible={setVisible}
            slide={slide}
            setSlide={setSlide}
          />
          <ChatbotBody
            interactions={interactions}
            setInteractions={setInteractions}
          />
          <ChatbotBottom
            interactions={interactions}
            setInteractions={setInteractions}
          />
        </Box>
      )}
    </>
  );
};

const Chatbot = () => {
  const [render, setRender] = useState(false);
  const [visible, setVisible] = useState(false);
  const [interactions, setInteractions] = useState([
    {
      id: generateRandomId(),
      actor: 'User',
      message: 'Hey! What questions can I ask about Senior Design Day?',
    },
  ]);

  useEffect(() => {
    async function fetchConfig() {
      try {
        const response = await getMainConfig();
        setRender(response?.data?.data?.misc?.showChatBot || false);
      } catch (error) {
        console.log(error);
      }
    }

    fetchConfig();
  }, []);

  return (
    render && (
      <>
        <ChatbotIcon visible={visible} setVisible={setVisible} />

        <ChatbotScreen
          visible={visible}
          setVisible={setVisible}
          interactions={interactions}
          setInteractions={setInteractions}
        />
      </>
    )
  );
};

export default Chatbot;

import { Container } from '@mui/material';
import 'react-quill/dist/quill.snow.css';
export default function Dynamic({ content }) {
  return (
    <>
      <Container maxWidth="lg" sx={{ marginY: 1 }}>
        <div
          className="ql-editor"
          dangerouslySetInnerHTML={{
            __html: content,
          }}
          style={{
            whiteSpace: 'pre-wrap',
          }}
        />
      </Container>
    </>
  );
}

import { Box, Input } from '@mui/material';
import { useState } from 'react';

export default function Video({ component, handleChange }) {
  const [localContent, setLocalContent] = useState(component?.videoLink || '');
  function handleContentChange() {
    const newComponent = { ...component, videoLink: localContent };
    handleChange(newComponent);
  }
  return (
    <Box display="flex" flexDirection={'column'} gap={1}>
      <h5>Youtube Link</h5>
      <Input
        value={localContent}
        placeholder='Enter the video link: Ex: "https://www.youtube.com/embed/VIDEO_ID"'
        fullWidth
        onChange={(e) => setLocalContent(e.target.value)}
        onBlur={() => handleContentChange()}
      />
    </Box>
  );
}

import { Box, Input } from '@mui/material';
import { useState } from 'react';

// Text banner has title: text, body: text, bgcolor: text, textColor: text
export function TextBanner({ component, handleChange }) {
  const [localContent, setLocalContent] = useState(component?.text || '');
  const [localContent2, setLocalContent2] = useState(component?.body || '');
  const [localContent3, setLocalContent3] = useState(component?.bgcolor || '');
  const [localContent4, setLocalContent4] = useState(
    component?.textColor || ''
  );
  function handleContentChange() {
    const newComponent = { ...component, text: localContent };
    handleChange(newComponent);
  }
  function handleContentChange2() {
    const newComponent = { ...component, body: localContent2 };
    handleChange(newComponent);
  }
  function handleContentChange3() {
    const newComponent = { ...component, bgcolor: localContent3 };
    handleChange(newComponent);
  }
  function handleContentChange4() {
    const newComponent = { ...component, textColor: localContent4 };
    handleChange(newComponent);
  }

  return (
    <Box display="flex" flexDirection={'column'} gap={2}>
      <h5>Title</h5>
      <Input
        value={localContent}
        fullWidth
        onChange={(e) => setLocalContent(e.target.value)}
        onBlur={() => handleContentChange()}
      />

      <h5>Body</h5>
      <Input
        value={localContent2}
        fullWidth
        onChange={(e) => setLocalContent2(e.target.value)}
        onBlur={() => handleContentChange2()}
      />

      <h5>Background Color</h5>
      <Input
        value={localContent3}
        fullWidth
        onChange={(e) => setLocalContent3(e.target.value)}
        onBlur={() => handleContentChange3()}
      />

      <h5>Text Color</h5>
      <Input
        value={localContent4}
        fullWidth
        onChange={(e) => setLocalContent4(e.target.value)}
        onBlur={() => handleContentChange4()}
      />
    </Box>
  );
}

export default function TwoButtonLink({ component, handleChange }) {
  const [localContent, setLocalContent] = useState(
    component?.button1Link || ''
  );
  const [localContent2, setLocalContent2] = useState(
    component?.button1Text || ''
  );
  const [localContent3, setLocalContent3] = useState(
    component?.button2Link || ''
  );
  const [localContent4, setLocalContent4] = useState(
    component?.button2Text || ''
  );
  function handleContentChange() {
    const newComponent = { ...component, button1Link: localContent };
    handleChange(newComponent);
  }
  function handleContentChange2() {
    const newComponent = { ...component, button1Text: localContent2 };
    handleChange(newComponent);
  }
  function handleContentChange3() {
    const newComponent = { ...component, button2Link: localContent3 };
    handleChange(newComponent);
  }
  function handleContentChange4() {
    const newComponent = { ...component, button2Text: localContent4 };
    handleChange(newComponent);
  }

  return (
    <Box display="flex" flexDirection={'column'} gap={2}>
      <h5>Button 1 Link</h5>
      <Input
        value={localContent}
        fullWidth
        onChange={(e) => setLocalContent(e.target.value)}
        onBlur={() => handleContentChange()}
      />

      <h5>Button 1 Text</h5>
      <Input
        value={localContent2}
        fullWidth
        onChange={(e) => setLocalContent2(e.target.value)}
        onBlur={() => handleContentChange2()}
      />

      <h5>Button 2 Link</h5>
      <Input
        value={localContent3}
        fullWidth
        onChange={(e) => setLocalContent3(e.target.value)}
        onBlur={() => handleContentChange3()}
      />

      <h5>Button 2 Text</h5>
      <Input
        value={localContent4}
        fullWidth
        onChange={(e) => setLocalContent4(e.target.value)}
        onBlur={() => handleContentChange4()}
      />
    </Box>
  );
}

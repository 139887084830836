import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ArticleIcon from '@mui/icons-material/Article';
import HomeIcon from '@mui/icons-material/Home';
import { Score } from '@mui/icons-material';
import EventIcon from '@mui/icons-material/Event';
import ContactPageIcon from '@mui/icons-material/ContactPage';
import { Link } from 'react-router-dom';

export const mainListItems = (showProjectBids = false) => (
  <React.Fragment>
    <Link
      style={{ textDecoration: 'none', color: 'inherit' }}
      to={`/judge/dashboard`}
    >
      <ListItemButton>
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary="Dashboard" />
      </ListItemButton>
    </Link>
    <Link
      style={{ textDecoration: 'none', color: 'inherit' }}
      to={`/judge/dashboard/assignments`}
    >
      <ListItemButton>
        <ListItemIcon>
          <Score />
        </ListItemIcon>
        <ListItemText primary="Assignments" />
      </ListItemButton>
    </Link>
    {showProjectBids && (
      <Link
        style={{ textDecoration: 'none', color: 'inherit' }}
        to={`/judge/dashboard/projects`}
      >
        <ListItemButton>
          <ListItemIcon>
            <ArticleIcon />
          </ListItemIcon>
          <ListItemText primary="Projects Bids" />
        </ListItemButton>
      </Link>
    )}
  </React.Fragment>
);

export const secondaryListItems = (pid) => (
  <React.Fragment>
    <ListSubheader component="div" inset>
      Atendee Pages
    </ListSubheader>
    <Link style={{ textDecoration: 'none', color: 'inherit' }} to="/">
      <ListItemButton>
        <ListItemIcon>
          <HomeIcon />
        </ListItemIcon>
        <ListItemText primary="Home" />
      </ListItemButton>
    </Link>
    <Link
      style={{ textDecoration: 'none', color: 'inherit' }}
      to="/participant-info"
    >
      <ListItemButton>
        <ListItemIcon>
          <EventIcon />
        </ListItemIcon>
        <ListItemText primary="Participant" />
      </ListItemButton>
    </Link>
    <Link style={{ textDecoration: 'none', color: 'inherit' }} to="/contact">
      <ListItemButton>
        <ListItemIcon>
          <ContactPageIcon />
        </ListItemIcon>
        <ListItemText primary="Contact" />
      </ListItemButton>
    </Link>
  </React.Fragment>
);

import * as React from 'react';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Dashboard from '../../../../components/Judge/Dashboard.js';
import { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { Styles } from '../../../../styles/global.js';
import { getMyAssignments } from '../../../../utils/API.js';
import 'chart.js/auto';
import {
  Typography,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Alert,
} from '@mui/material';
import { useAuth } from '../../../../utils/AuthContext.js';
import { ExpandMore } from '@mui/icons-material';
import SearchProject from '../../../../components/Entity/SearchProject.js';

// Page that shows when a project is already created
const JudgeAssignments = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [assignments, setAssignments] = useState([]);

  function filter(assignments, status) {
    return assignments.filter((a) => a?.status === status) || [];
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (user?.role !== 'JUDGE') {
          navigate('/');
          return;
        }
        const response = await getMyAssignments();
        setAssignments(response?.data?.data);
      } catch (error) {}
    };

    fetchData();
  }, []);

  return (
    <>
      <Box {...Styles.mainBox}>
        <Toolbar />

        {/* Container With Max Width = lg breakpoint */}

        <Container {...Styles.customContainer()}>
          {/*Outer Grid Container */}
          <Grid container spacing={3}>
            {/*Inner Grid Begins*/}

            {/*Inner Grid Item 1: Page Title */}
            <Grid item lg={12} sm={12} xs={12}>
              <Paper {...Styles.dashboardPageWidget()}>My Assignments</Paper>
            </Grid>

            {/* Inner Grid Item 2: User Detials */}
            <Grid item lg={12} md={6} sm={12} xs={12}>
              <Paper>
                <Accordion defaultExpanded>
                  <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>
                      <strong> Pending Assignments: </strong>{' '}
                      {filter(assignments, 'PENDING')?.length}
                    </Typography>
                  </AccordionSummary>

                  <AccordionDetails>
                    <Divider />
                    {assignments &&
                      filter(assignments, 'PENDING').length === 0 && (
                        <Alert>No Pending Assignments</Alert>
                      )}
                    {assignments &&
                      filter(assignments, 'PENDING').map((assignment) => (
                        <Link
                          to={`/judge/dashboard/assignments/${assignment?.projectDetails?._id}`}
                          key={assignment?._id}
                          style={{ textDecoration: 'none', color: 'inherit' }}
                        >
                          <SearchProject
                            project={assignment?.projectDetails}
                            key={assignment?._id}
                          />
                        </Link>
                      ))}
                  </AccordionDetails>
                </Accordion>
              </Paper>
            </Grid>
            <Grid item lg={12} md={6} sm={12} xs={12}>
              <Paper>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>
                      <strong> Completed Assignments: </strong>{' '}
                      {filter(assignments, 'COMPLETED')?.length}
                    </Typography>
                  </AccordionSummary>

                  <AccordionDetails>
                    <Divider />
                    {assignments &&
                      filter(assignments, 'COMPLETED').map((assignment) => (
                        <Link
                          to={`/judge/dashboard/assignments/${assignment?.projectDetails?._id}`}
                          key={assignment?._id}
                          style={{ textDecoration: 'none', color: 'inherit' }}
                        >
                          <SearchProject
                            project={assignment?.projectDetails}
                            key={assignment?._id}
                          />
                        </Link>
                      ))}
                  </AccordionDetails>
                </Accordion>
              </Paper>
            </Grid>
            {/*End of Inner Grid */}
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default function JudgeDashboardAssignments() {
  return <Dashboard Page={JudgeAssignments} />;
}

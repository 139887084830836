import { Box, Input } from '@mui/material';
import { useState } from 'react';
import { uploadImage } from '../../../utils/API';

export default function Image({ component, handleChange }) {
  const [localContent, setLocalContent] = useState(component?.alt || '');
  async function handleImageChange(e) {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append('file', file);
    try {
      const res = await uploadImage(formData);
      const newComponent = { ...component, src: res.data.data };
      handleChange(newComponent);
    } catch (err) {
      console.log(err);
      alert('Failed to upload image');
    }
  }
  function handleContentChange() {
    const newComponent = { ...component, alt: localContent };
    handleChange(newComponent);
  }

  return (
    <Box display="flex" flexDirection={'column'} gap={2}>
      <h5>Image Src</h5>
      <Box display="flex" justifyContent={'space-between'}>
        <Input
          value={component?.src || ''}
          disabled={true}
          placeholder="No File Chosen"
          accept="image/*"
          fullWidth
        />
        <Input type="file" onChange={(e) => handleImageChange(e)} />
      </Box>

      <h5>Image Alt Text</h5>
      <Input
        value={localContent}
        fullWidth
        onChange={(e) => setLocalContent(e.target.value)}
        onBlur={() => handleContentChange()}
      />
    </Box>
  );
}

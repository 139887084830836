import { Box, Input } from '@mui/material';
import { useState } from 'react';

export default function Link({ component, handleChange }) {
  const [localContent, setLocalContent] = useState(component?.to || '');
  const [localContent2, setLocalContent2] = useState(component?.text || '');
  function handleContentChange(whichOne) {
    if (whichOne) {
      const newComponent = { ...component, to: localContent };
      handleChange(newComponent);
    } else {
      const newComponent = { ...component, text: localContent2 };
      handleChange(newComponent);
    }
  }
  return (
    <Box display="flex" flexDirection={'column'} gap={2}>
      <h5>URL Link</h5>
      <Input
        value={localContent}
        fullWidth
        onChange={(e) => setLocalContent(e.target.value)}
        onBlur={() => handleContentChange(true)}
      />
      <h5>Link Text</h5>
      <Input
        value={localContent2}
        fullWidth
        onChange={(e) => setLocalContent2(e.target.value)}
        onBlur={() => handleContentChange(false)}
      />
    </Box>
  );
}

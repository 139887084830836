import { Box, Divider, Typography, Avatar, Button, Input } from '@mui/material';
import { StylesForm } from '../../../styles/global';
import AdvancedModal from '../../AdvancedModal';
import { useState } from 'react';
import { sendMessage } from '../../../utils/API';
export default function InformInbox({
  project,
  refresh,
  setRefesh,
  editMode = false,
}) {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const send = async () => {
    try {
      const response = await sendMessage(project._id, { message });
      console.log(response.data.data);
      alert('Message sent');
      setRefesh(!refresh);
    } catch (error) {
      console.error('Error sending message:', error);
      alert('Error sending message');
    }
    setOpen(false);
  };

  return (
    <>
      {project && (
        <>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            {/* Project Title */}
            <Typography {...StylesForm.text('h6')}>Project Inbox</Typography>
            {editMode ? (
              <Button onClick={() => setOpen(true)}>Send Message</Button>
            ) : (
              <Typography {...StylesForm.text('p')}>
                {project?.inbox?.length} Messages
              </Typography>
            )}
          </Box>

          <Divider sx={{ marginX: 1 }} />

          {project?.inbox?.length > 0 && (
            <Box overflow={'auto'} width={'100%'}>
              {project.inbox.map((message, index) => (
                <Box key={index}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Avatar sx={{ width: 30, height: 30, marginRight: 1 }}>
                      {message.sender[0]}
                    </Avatar>
                    <Typography {...StylesForm.text('p')}>
                      {message.sender}
                    </Typography>
                  </Box>
                  <Box sx={{ marginLeft: 5 }}>
                    <Typography {...StylesForm.text('subtitle2')}>
                      {message.message}
                    </Typography>
                  </Box>
                  <Divider sx={{ margin: 1 }} />
                </Box>
              ))}
            </Box>
          )}
          <AdvancedModal
            open={open}
            title={'Send Message'}
            cta={'Send'}
            handleSave={send}
            handleClose={() => setOpen(false)}
          >
            <Box
              sx={{
                padding: 2,
                border: 1,
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
              }}
            >
              <Typography>Send a message to the project owners:</Typography>
              <Input
                variant={'contained'}
                placeholder={'Write your message here'}
                fullWidth
                multiline
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
            </Box>
          </AdvancedModal>
        </>
      )}
    </>
  );
}

import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Avatar } from '@mui/material';
import { Button } from '@mui/material';
import { deleteInvite, getInvites } from '../utils/API';

export default function InvitesTable({ rows, setInvites }) {
  const handleClick = async (row) => {
    try {
      await deleteInvite(row?.requestToken);
      const response = await getInvites();
      setInvites(response?.data?.data || []);
      alert('Invite deleted');
    } catch (error) {
      console.log(error);
      alert('Error while deleting invite');
    }
  };
  return (
    <TableContainer component={Paper} sx={{ p: '20px' }}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Users</TableCell>
            <TableCell align="right">Email</TableCell>
            <TableCell align="right">Department</TableCell>
            <TableCell align="right">Course</TableCell>
            <TableCell align="right">Role</TableCell>
            <TableCell align="right">Year</TableCell>
            <TableCell align="right"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.token}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                <Avatar alt={row?.fullName} src={row?.avatar} />
              </TableCell>
              <TableCell align="right">
                {row?.email.length > 25
                  ? `${row?.email.substring(0, 25)}...`
                  : row?.email}
              </TableCell>
              <TableCell align="right">{row?.department}</TableCell>
              <TableCell align="right">{row?.course}</TableCell>
              <TableCell align="right">{row?.role}</TableCell>
              <TableCell align="right">{row?.date}</TableCell>
              <TableCell align="right">
                <Button
                  variant="outlined"
                  color="error"
                  onClick={() => handleClick(row)}
                >
                  Delete
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

import { Box, Typography, Container, Paper } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import NavBar from '../../components/AttendeeNavBar.js';
import Chatbot from '../../components/ChatBot.js';
import { useAuth } from '../../utils/AuthContext.js';
import Card from '../../components/Card.js';
import TextBanner from '../../components/Atendee/UI/TextBanner.js';
import Footer from '../../components/Atendee/UI/Footer.js';

const Preset = {
  title: 'Judge Portal',
  description: 'Navigate Your Senior Design Journey with Ease',
  portal: 'Portal',
  image: `https://senior-design-competion-production.nyc3.cdn.digitaloceanspaces.com/STATIC/istockphoto-1389697172-612x612.jpg`,
  cardTitle: 'Judge Portal',
  cardBody:
    'The UM-Dearborn Student SDC Portal is the centralized online platform for submitting senior design projects.',
  date: 'Welcome!',
  feature: 'Judge Features',
};

const MainLayout = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  bgcolor: '#FAFAFA',
};

const Banner1 = ({ content }) => {
  return (
    <>
      <Box
        sx={{
          backgroundColor: '#00274C',
          height: '25vh',
          width: '100%',
          display: 'flex',
          justifyContent: 'left',
          alignItems: 'center',
        }}
      >
        <Container>
          <Typography
            variant="h1"
            sx={{
              fontWeight: '100',
              textAlign: 'left',
              fontFamily: 'Lato, Arial, sans-serif',
              color: 'white',
              letterSpacing: '2px',
              fontSize: '40px',
            }}
          >
            {' '}
            {content.title}{' '}
          </Typography>
          <Typography
            variant="h6"
            sx={{
              fontWeight: '100',
              textAlign: 'left',
              fontFamily: 'Lato, Arial, sans-serif',
              color: 'white',
              letterSpacing: '2px',
              my: 2,
              fontSize: '16px',
            }}
          >
            {content.description}
          </Typography>
        </Container>
      </Box>
    </>
  );
};

const Banner2 = ({ content }) => {
  return (
    <>
      <Box
        id="banner1"
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#ffdb53',
          py: '45px',
          width: '100%',
          gap: 3,
        }}
      >
        <Typography
          variant="h6"
          sx={{
            fontWeight: '100',
            fontFamily: 'Lato, Arial, sans-serif',
            color: '#00274c',
            letterSpacing: '2px',
            fontSize: '2.3vw',
          }}
        >
          {content.feature}
        </Typography>
        <Features content={content} />
      </Box>
    </>
  );
};

const Portal = ({ content }) => {
  const { user, isLoggedIn } = useAuth();
  const navigate = useNavigate();

  // Handles the navigation to portal
  const handleNavigation = () => {
    if (isLoggedIn && user?.role === 'JUDGE') {
      navigate('/judge/dashboard');
    }

    navigate('/judge/login');
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          gap: 4,
          alignItems: 'center',
          py: 3,
          m: 2,
        }}
      >
        <Card
          image={content.image}
          cardTitle={content.cardTitle}
          cardBody={content.cardBody}
          onClick={handleNavigation}
        />
        <Paper
          elevation={3}
          sx={{
            fontSize: '20px',
            p: 3,
          }}
        >
          {content.date}
        </Paper>
      </Box>
    </>
  );
};

const Features = ({ content }) => {
  return (
    <>
      <Box sx={{ p: 1, m: 1 }}>
        <ul>
          <li>Creating Projects</li>
          <li>Abstract Submission</li>
          <li>Poster Submission</li>
          <li>Organizing Teams</li>
        </ul>
      </Box>
    </>
  );
};

const Judge = () => {
  const [content] = useState(Preset);

  return (
    <>
      <NavBar />
      <TextBanner
        text="Judge Portal"
        body="The UM-Dearborn Judge SDC Portal is the centralized online platform for judging senior design projects."
        bgcolor="#00274C"
        textColor="white"
      />
      <Portal content={content} />
      <TextBanner
        text="Judge Features"
        body="The UM-Dearborn Judge SDC Portal is the centralized online platform for judging senior design projects. Score Projects, View Abstracts, and View Posters."
        bgcolor="#ffdb53"
        textColor="#00274c"
      />
      <Footer />
    </>
  );
};

export default Judge;

import { Box, Divider, Typography } from '@mui/material';
import { StylesForm } from '../../../styles/global';
import {
  Timeline,
  TimelineItem,
  TimelineOppositeContent,
  TimelineDot,
  TimelineConnector,
  TimelineSeparator,
  TimelineContent,
} from '@mui/lab';
import { CheckSharp } from '@mui/icons-material';
import { formatDate } from '../../../utils/dates';

// Widget for Project Timeline Steps
const InformTimeline = ({ project, dueDates, showLink = false }) => {
  return (
    <>
      {project && (
        <>
          {/* Project Title + Due Date */}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            {/* Timeline Steps */}
            <Typography {...StylesForm.text('h6')}>Timeline Steps</Typography>
          </Box>
          <Divider />

          <Timeline position="alternate">
            <TimelineItem>
              <TimelineOppositeContent>
                <CheckSharp />
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot color="success" />
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>Project Created</TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineOppositeContent>
                Invite Team Members
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot
                  color={project?.data?.owners?.length > 1 ? 'success' : 'grey'}
                />
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                {project?.data?.owners?.length > 1 && <CheckSharp />}
                <TimelineConnector />
              </TimelineContent>
            </TimelineItem>

            <TimelineItem>
              <TimelineOppositeContent color="text.secondary">
                {formatDate(dueDates?.abstract)}
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot
                  color={project?.data?.abstract?.length ? 'success' : 'grey'}
                />
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>Abstract</TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineOppositeContent>
                Project Link / Image DRAFT
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot
                  color={project?.data?.posterLink ? 'success' : 'grey'}
                />
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent color="gray">
                {formatDate(dueDates?.posterLink)}
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineOppositeContent color="gray">
                {formatDate(dueDates?.posterLinkFinal)}
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot
                  color={
                    project?.data?.posterLink &&
                    new Date().now > dueDates?.posterLink
                      ? 'success'
                      : 'grey'
                  }
                />
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>Project Link / Image FINAL</TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineOppositeContent>
                Show Up For Senior Design Day
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot color={'info'} />
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <Box>{formatDate(dueDates?.seniorDesignDay)}</Box>
              </TimelineContent>
            </TimelineItem>
          </Timeline>
        </>
      )}
    </>
  );
};

export default InformTimeline;
